import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faStore,
    faCheck,
    faEraser,
    faXmark,
    faCamera,
} from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { useRef, useState } from 'react';

function ShopDetail({
    shopInfo,
    handleCloseEdit,
    handleOpenEdit,
    openEdit,
    handleUpdateShop,
    isLoading,
    errorMsg,
}) {
    const [newShopName, setNewShopName] = useState();
    const [image, setImage] = useState();
    const fileRef = useRef();
    const [onHover, setOnHover] = useState(false);

    const handleBrowse = () => {
        fileRef.current.click();
    };

    const handeSetFile = (e) => {
        setImage(e.target.files[0]);
    };

    const handleClose = () => {
        fileRef.current.value = null;
        setImage(null);
        handleCloseEdit();
    };

    const onUpdate = () => {
        if ((newShopName || image) && newShopName !== shopInfo.shopname) {
            handleUpdateShop(newShopName, image);
        }
    };

    let urlImg;
    if (image) urlImg = URL.createObjectURL(image);

    return (
        <div className="border bg-white rounded-xl p-5 flex justify-between">
            <div className="flex-1">
                <div className="flex w-full gap-3 overflow-auto">
                    <div
                        onMouseOver={() => setOnHover(true)}
                        onMouseLeave={() => setOnHover(false)}
                        onClick={openEdit.shop ? handleBrowse : () => {}}
                        className={`w-16 h-16 rounded-full flex items-center justify-center bg-violet-50 overflow-hidden relative `}
                    >
                        {openEdit.shop && (
                            <div className="w-full">
                                <input
                                    onChange={handeSetFile}
                                    hidden
                                    ref={fileRef}
                                    type="file"
                                    accept="image/*"
                                />
                            </div>
                        )}
                        {!shopInfo.logo ? (
                            <FontAwesomeIcon
                                icon={faStore}
                                className={`text-xl ${
                                    openEdit.shop && 'hidden'
                                }`}
                            />
                        ) : (
                            <img
                                className={`h-full object-cover ${
                                    openEdit.shop && urlImg
                                        ? 'hidden'
                                        : openEdit.shop && 'opacity-60'
                                }`}
                                alt="logo"
                                src={`${process.env.REACT_APP_API_URL}/img/shops/${shopInfo.logo}`}
                            />
                        )}
                        {openEdit.shop && (
                            <div className="absolute top-0 flex items-center justify-center left-0 h-full w-full z-20 hover:opacity-60">
                                {urlImg ? (
                                    <img
                                        alt="new-logo"
                                        src={urlImg}
                                        className="h-full object-cover"
                                    />
                                ) : (
                                    <FontAwesomeIcon icon={faCamera} />
                                )}
                            </div>
                        )}
                        <FontAwesomeIcon
                            className={`absolute ${
                                (!openEdit.shop || !onHover) && 'hidden'
                            }`}
                            icon={faCamera}
                        />
                    </div>
                    <div className="flex flex-1 flex-col justify-between cursor-default overflow-auto">
                        <div>
                            {openEdit.shop ? (
                                <div className="flex flex-col gap-1 w-full">
                                    <div className="flex gap-2">
                                        <input
                                            className="bg-slate-200 w-80 rounded-lg px-2 py-1 outline-none"
                                            onChange={(e) =>
                                                setNewShopName(e.target.value)
                                            }
                                            autoFocus
                                            type="text"
                                            defaultValue={shopInfo.shopname}
                                        />
                                        <span className="text-xs">
                                            *Tối thiểu 4 ký tự
                                        </span>
                                    </div>
                                    {errorMsg && (
                                        <div className="overflow-auto">
                                            <span className="text-red-500 text-xs">
                                                {`*${errorMsg}`}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <span>{shopInfo.shopname || 'Shop name'}</span>
                            )}
                        </div>
                        <span className="text-sm text-slate-700">
                            {`Chủ sở hữu: ${shopInfo.owner?.name || 'unknown'}`}
                        </span>
                        <span className="text-xs text-slate-500">
                            {`Ngày tạo:   ${
                                shopInfo.createAt
                                    ? format(
                                          new Date(shopInfo.createAt),
                                          'dd/MM/yyyy HH:mm:ss'
                                      )
                                    : 'unknown'
                            }`}
                        </span>
                    </div>
                </div>
            </div>
            <div>
                <div className="flex">
                    <span
                        onClick={handleOpenEdit}
                        data-value="shop"
                        className={` text-sm border rounded-2xl py-1 px-2 cursor-pointer hover:border-slate-900 ${
                            openEdit.shop && 'hidden'
                        }`}
                    >
                        Sửa{' '}
                        <FontAwesomeIcon
                            className="pointer-events-none"
                            icon={faEraser}
                        />
                    </span>
                </div>
                <div className="flex gap-2">
                    <span
                        onClick={onUpdate}
                        className={`w-14 text-center bg-violet-500 text-sm text-white border rounded-2xl py-1 px-2  hover:opacity-60 ${
                            !openEdit.shop && 'hidden'
                        } ${
                            (!newShopName && !image) ||
                            newShopName === shopInfo.shopname ||
                            isLoading
                                ? 'opacity-70 cursor-not-allowed'
                                : 'cursor-pointer'
                        }`}
                    >
                        <FontAwesomeIcon
                            className="pointer-events-none"
                            icon={faCheck}
                        />
                    </span>
                    <span
                        onClick={handleClose}
                        className={`w-14 text-center text-sm border rounded-2xl py-1 px-2 cursor-pointer hover:border-slate-900 ${
                            !openEdit.shop && 'hidden'
                        }`}
                    >
                        <FontAwesomeIcon
                            className="pointer-events-none"
                            icon={faXmark}
                        />
                    </span>
                </div>
            </div>
        </div>
    );
}

export default ShopDetail;
