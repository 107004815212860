import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Validator from '../../utils/Validator';
import authApi from '../../api/authApi';
import FormInput from '../../components/FormInput/FormInput';
import styles from './Signup.module.css';
import Button from '../../components/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons';

function Signup() {
    const navigate = useNavigate();
    const formInput = [
        {
            id: '1',
            name: 'name',
            type: 'text',
            placeholder: 'Tên của bạn',
            label: 'Name',
            errorMessage: 'Tên có ít nhất 3 ký tự trở lên',
        },
        {
            id: '2',
            name: 'email',
            type: 'text',
            placeholder: 'Email',
            label: 'email',
            errorMessage: 'Vui lòng nhập email của bạn',
        },
        {
            id: '3',
            name: 'password',
            type: 'password',
            placeholder: 'Mật khẩu',
            label: 'Nhập lại mật khẩu',
            errorMessage: 'Mật khẩu tối thiểu 8 ký tự.',
        },
        {
            id: '4',
            name: 'passwordConfirm',
            type: 'password',
            placeholder: 'Nhập lại mật khẩu',
            label: 'passwordConfirm',
            errorMessage: 'Mật khẩu không trùng khớp',
        },
    ];
    const [input, setInput] = useState({
        name: '',
        email: '',
        password: '',
        passwordConfirm: '',
    });

    const [inputValid, setInputValid] = useState({
        name: true,
        email: true,
        password: true,
        passwordConfirm: true,
    });

    const [submitting, setSubmitting] = useState(false);
    const [errMsg, setErrMsg] = useState();

    const validate = {
        email: () => {
            return new Validator(input.email).isEmail().isRequired().valid;
        },
        name: () => {
            return new Validator(input.name).minLength(3).isRequired().valid;
        },

        password: () => {
            return new Validator(input.password).isPassword().isRequired()
                .valid;
        },
        passwordConfirm: () => {
            return new Validator(input.passwordConfirm)
                .isMatch(input.password)
                .isRequired().valid;
        },
    };

    const validAll = Object.keys(input).every((key) => {
        return validate[key]();
    });

    const handleBlur = (e) => {
        setInputValid((prev) => {
            return { ...prev, [e.target.name]: validate[e.target.name]() };
        });
    };
    const handleOnchange = (e) => {
        setInputValid((prev) => {
            return { ...prev, [e.target.name]: true };
        });
        setInput((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    const handleSubmit = async () => {
        if (!validAll) {
            return;
        }
        setSubmitting(true);
        try {
            const { data } = await authApi.signup(input);
            localStorage.setItem('access_token', data.token);
            localStorage.setItem('user', JSON.stringify(data.user));
            navigate('/shop');
            setSubmitting(false);
        } catch ({ response }) {
            setSubmitting(false);
            setErrMsg(response.data.message);
        }
    };

    const wrapperEnter = (e) => {
        if (e.key === 'Enter') handleSubmit();
    };

    return (
        <div
            onKeyDown={wrapperEnter}
            className="h-screen flex justify-center items-center"
        >
            <div className="w-full px-8 py-10 sm:w-96 sm:shadow sm:rounded-lg sm:bg-white ">
                <p className="block text-2xl mb-3 font-semibold">
                    Tạo tài khoản
                </p>

                {errMsg && (
                    <div className={styles.errorApi}>
                        <FontAwesomeIcon icon={faXmarkCircle} />
                        <p>{errMsg}</p>
                    </div>
                )}

                <FormInput
                    onBlur={handleBlur}
                    onChange={handleOnchange}
                    formInput={formInput}
                    inputValid={inputValid}
                />

                <Button
                    rounder
                    disable={!validAll || submitting}
                    onClick={handleSubmit}
                    height={50}
                    marginBottom={20}
                >
                    Đăng ký
                </Button>

                <div className="flex justify-center items-center text-violet-900">
                    <span className="mr-1 text-gray-500">
                        Bạn đã có tài khoản?{' '}
                    </span>
                    <Link to="/login">Đăng nhập</Link>
                </div>
            </div>
        </div>
    );
}

export default Signup;
