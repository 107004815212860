import React, { useState } from 'react';
import YearPicker from './YearPicker';
import YearMonthPicker from './YearMonthPicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';

function WeekMonthYearPicker({ timeSelect, setTimeSelect }) {
    const [openPicker, setOpenPicker] = useState(false);
    const [hover, setHover] = useState({
        week: false,
        month: true,
        year: false,
    });

    const handleHover = (e) => {
        const value = e.target.dataset.value;
        let newHover = { ...hover };
        Object.keys(newHover).forEach((el) => (newHover[el] = value === el));
        setHover(newHover);
    };

    const handleOpenPicker = () => {
        setOpenPicker((prev) => !prev);
    };

    const handleTimeSelect = (e, name) => {
        let value = e;
        if (name === 'year') value = value.target.dataset.value * 1;
        let newTimeSelect = { ...timeSelect };
        Object.keys(newTimeSelect).forEach((el) => {
            if (el === name) return (newTimeSelect[el] = value);
            return (newTimeSelect[el] = undefined);
        });
        setTimeSelect(newTimeSelect);
    };

    const convertTitleChart = () => {
        if (timeSelect.month)
            return `Tháng ${format(new Date(timeSelect.month), 'MM/yyyy')}`;
        if (timeSelect.year) return `Năm ${timeSelect.year}`;
    };

    return (
        <div
            tabIndex={0}
            onClick={handleOpenPicker}
            onBlur={() => setOpenPicker(false)}
            className=" relative cursor-default border h-full w-full rounded-lg hover:border-slate-900"
        >
            <div className="p-1 inline-flex gap-x-2 items-center">
                <span className="leading-none"> {convertTitleChart()}</span>
                <FontAwesomeIcon className="text-xs" icon={faChevronDown} />
            </div>
            <div
                onClick={(e) => e.stopPropagation()}
                className={`absolute top-full whitespace-nowrap h-auto cursor-default bg-white mt-1 flex shadow-lg rounded-lg overflow-auto ${
                    !openPicker && 'hidden'
                }`}
            >
                <ul>
                    <li
                        data-value="month"
                        onMouseOver={handleHover}
                        className={`py-1 px-2 ${
                            hover.month && 'bg-violet-100'
                        }`}
                    >
                        Theo Tháng
                    </li>
                    <li
                        data-value="year"
                        onMouseOver={handleHover}
                        className={`py-1 px-2 ${hover.year && 'bg-violet-100'}`}
                    >
                        Theo Năm
                    </li>
                    {/* <li
                        data-value="week"
                        onMouseOver={handleHover}
                        className="py-1 px-2 hover:bg-violet-100"
                    >
                        Theo Tuần
                    </li> */}
                </ul>

                <div className=" bg-white p-1 w-72 h-72 relative shadow-lg">
                    {hover.year && (
                        <YearPicker
                            dataset="year"
                            onMouseOver={handleHover}
                            setOpenYear={setOpenPicker}
                            setYear={handleTimeSelect}
                            year={timeSelect.year || new Date().getFullYear()}
                            openYear={true}
                            name="year"
                        />
                    )}
                    {hover.month && (
                        <YearMonthPicker
                            setOpenMonth={setOpenPicker}
                            month={timeSelect.month}
                            name="month"
                            openMonth={true}
                            setMonth={handleTimeSelect}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default WeekMonthYearPicker;
