import React, { useEffect, useState } from 'react';
import DragDropFile from '../../../components/DragDropFile';
import Button from '../../../components/Button/Button';
import orderApi from '../../../api/orderApi';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBox,
    faCloudUpload,
    faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import handleErrorAsync from '../../../utils/handleCatchAsync';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';

function OrderCancelled() {
    const params = useParams();
    const [file, setFile] = useState();
    const [textarea, setTextarea] = useState([]);
    const [selected, setSelected] = useState(null);
    const [validate, setValidate] = useState(true);
    const [onSubmit, setOnSubmit] = useState(false);
    const [dataSuccess, setDataSuccess] = useState([]);
    const [uploadErrorMsg, setUploadErrorMsg] = useState('');

    const handleDeleteFile = () => {
        setFile(undefined);
        setValidate(true);
    };

    const handleSubmit = async () => {
        if ((!selected || !file) && !textarea.length > 0)
            return setValidate(false);
        setOnSubmit(true);
        setDataSuccess({});
        setUploadErrorMsg('');

        if (file) {
            const form = new FormData();
            form.append('platform', selected.value);
            form.append('xlsx', file);

            // try {
            await orderApi
                .updateOrderCancelledByFormData(params.shopId, form)
                .then(({ data }) => {
                    setDataSuccess(data.data);
                })
                .catch((error) => handleErrorAsync(error, setUploadErrorMsg));
        }

        if (textarea.length > 0) {
            await orderApi
                .updateOrderCancelledByTextArea(params.shopId, {
                    cancelledList: textarea,
                })
                .then(({ data }) => {
                    setUploadErrorMsg('');
                    setDataSuccess(data.data);
                })
                .catch((error) => handleErrorAsync(error, setUploadErrorMsg));
        }

        setOnSubmit(false);
    };

    useEffect(() => {
        setDataSuccess([]);
        setSelected(null);
        setUploadErrorMsg('');
    }, [file]);

    const handleSelect = (value) => {
        setSelected(value);
        setValidate(true);
    };

    const handleTextArea = (e) => {
        const data = e.target.value.split('\n').filter((el) => el);
        setTextarea(data);
    };
    return (
        <div className="h-full w-full flex justify-center">
            <div className="h-full w-full flex flex-col gap-5 items-center justify-start 2xl:w-[1280px]">
                {!textarea.length > 0 && (
                    <div className="w-full flex justify-center ">
                        <DragDropFile
                            selected={selected}
                            validate={validate}
                            onSelect={handleSelect}
                            setFile={setFile}
                            deleteFile={handleDeleteFile}
                            file={file}
                        />
                    </div>
                )}
                {!file && !textarea.length > 0 && (
                    <div className="w-full px-10 py-5 ">
                        <span className="relative w-full block border-[0.5px] border-slate-300 before:content-['hoặc'] before:p-2 before:bg-zinc-50 before:absolute before:top-[50%] before:left-[50%] before:translate-x-[-50%] before:translate-y-[-50%]"></span>
                    </div>
                )}
                {!file && (
                    <div className="w-full ">
                        <textarea
                            rows="4"
                            onChange={handleTextArea}
                            className="w-full h-48 p-5 outline-slate-800 border-dotted border shadow-lg overflow-auto "
                            placeholder="Copy và Paste mã vận đơn. Lưu ý: mỗi đơn hàng một dòng"
                        />
                    </div>
                )}

                <div className="flex">
                    <Button
                        width={160}
                        rounder
                        disable={onSubmit}
                        onClick={handleSubmit}
                    >
                        {onSubmit ? (
                            <span className="animate-spin">
                                <FontAwesomeIcon icon={faSpinner} />
                            </span>
                        ) : (
                            <span>
                                <FontAwesomeIcon icon={faCloudUpload} /> Upload
                            </span>
                        )}
                    </Button>
                </div>

                {uploadErrorMsg && (
                    <span className="p-5 mt-5 bg-white border border-red-500">
                        {uploadErrorMsg}
                    </span>
                )}

                {dataSuccess.orderCounter && (
                    <div className="flex flex-col gap-2">
                        <FontAwesomeIcon
                            className="text-4xl text-green-500"
                            icon={faCheckCircle}
                        />
                        <span className="font-semibold">
                            Cập nhật đơn huỷ thành công
                        </span>
                        <div className="flex gap-2">
                            <span>Số đơn hàng:</span>
                            <span className="font-semibold flex gap-1 justify-center items-center">
                                <span>{dataSuccess.orderCounter || 0}</span>

                                <FontAwesomeIcon icon={faBox} />
                            </span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default OrderCancelled;
