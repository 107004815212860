import React, { useState } from 'react';
import Validator from '../../utils/Validator';
import usersApi from '../../api/userApi';

function ChangeUserPassword({ setOpenChangePassword, notifySuccess }) {
    const [input, setInput] = useState({
        password: {
            value: '',
            touched: false,
            invalid: false,
        },
        newPassword: {
            value: '',
            touched: false,
            invalid: undefined,
        },
        passwordConfirm: {
            value: '',
            touched: false,
            invalid: undefined,
        },
    });

    const [errorMsg, setErrorMsg] = useState('');

    const handleValidate = (e) => {
        const key = e.target.name;
        switch (key) {
            case 'newPassword':
                setInput((prev) => ({
                    ...prev,
                    [key]: {
                        ...prev[key],
                        invalid: !new Validator(e.target.value).minLength(8)
                            .valid,
                        touched: true,
                    },
                }));
                break;
            case 'passwordConfirm':
                setInput((prev) => ({
                    ...prev,
                    [key]: {
                        ...prev[key],
                        invalid: !new Validator(e.target.value).isMatch(
                            input.newPassword.value
                        ).valid,
                        touched: true,
                    },
                }));
                break;
            default:
                break;
        }
    };

    const handleOnChange = (e) => {
        setErrorMsg('');
        const key = e.target.name;

        switch (key) {
            case 'passwordConfirm':
                setInput((prev) => ({
                    ...prev,
                    [key]: {
                        ...prev[key],
                        invalid: !new Validator(e.target.value).isMatch(
                            input.newPassword.value
                        ).valid,
                        value: e.target.value.trim(),
                    },
                }));
                break;
            case 'newPassword':
                if (input.passwordConfirm.touched) {
                    setInput((prev) => ({
                        ...prev,
                        [key]: {
                            ...prev[key],
                            value: e.target.value.trim(),
                            invalid: false,
                        },
                        passwordConfirm: {
                            ...prev.passwordConfirm,
                            invalid: !new Validator(e.target.value).isMatch(
                                input.passwordConfirm.value
                            ).valid,
                        },
                    }));
                } else {
                    setInput((prev) => ({
                        ...prev,
                        [key]: {
                            ...prev[key],
                            value: e.target.value.trim(),
                            invalid: false,
                        },
                    }));
                }
                break;

            default:
                setInput((prev) => ({
                    ...prev,
                    [key]: {
                        ...prev[key],
                        value: e.target.value.trim(),
                    },
                }));
                break;
        }
    };

    const validateAll = Object.keys(input)
        .map((key) => input[key])
        .every((el) => el.invalid !== undefined && el.invalid === false);

    const handleOnSubmit = async () => {
        if (validateAll) {
            try {
                const { data } = await usersApi.changePassword(
                    input.password.value,
                    input.newPassword.value,
                    input.passwordConfirm.value
                );
                localStorage.setItem('access_token', data.token);
                notifySuccess('Đổi mật khẩu thành công');
                setOpenChangePassword(false);
            } catch (error) {
                if (error.response.status === 401)
                    return setErrorMsg('Mật khẩu cũ không chính xác');
                setErrorMsg(error.message);
            }
        }
    };

    return (
        <div className="absolute flex flex-col justify-center gap-5 top-0 left-0 h-full p-8 w-full rounded-lg bg-white">
            <span className="font-semibold">Đổi mật khẩu</span>
            <span className="text-red-500">{errorMsg}</span>
            <div className="flex flex-col gap-6">
                <input
                    value={input.password.value}
                    onChange={handleOnChange}
                    name="password"
                    placeholder="Mật khẩu"
                    className="bg-slate-200 p-3 rounded-lg focus-within:outline-slate-800"
                    type="text"
                />
                <div className="flex flex-col">
                    <input
                        value={input.newPassword.value}
                        onChange={handleOnChange}
                        onBlur={handleValidate}
                        name="newPassword"
                        placeholder="Mật khẩu mới"
                        className="bg-slate-200 p-3 rounded-lg focus-within:outline-slate-800"
                        type="text"
                    />
                    {input.newPassword.invalid && (
                        <span className="text-sm text-red-500">
                            Mật khẩu tối thiểu 8 ký tự
                        </span>
                    )}
                </div>
                <div className="flex flex-col">
                    <input
                        value={input.passwordConfirm.value}
                        onChange={handleOnChange}
                        onBlur={handleValidate}
                        name="passwordConfirm"
                        placeholder="Xác nhận mật khẩu"
                        className="bg-slate-200 p-3 rounded-lg focus-within:outline-slate-800"
                        type="text"
                    />
                    {input.passwordConfirm.invalid && (
                        <span className="text-sm text-red-500">
                            Mật khẩu không khớp
                        </span>
                    )}
                </div>
            </div>
            <div className="flex justify-end gap-3">
                <button
                    onClick={handleOnSubmit}
                    className={`bg-violet-500 text-white px-2 py-1 ${
                        !validateAll && 'opacity-60 cursor-not-allowed'
                    }`}
                >
                    Cập nhật
                </button>
                <button
                    onClick={() => setOpenChangePassword(false)}
                    className="border border-solid px-2 py-1"
                >
                    Huỷ
                </button>
            </div>
        </div>
    );
}

export default ChangeUserPassword;
