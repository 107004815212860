import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import authApi from '../../../api/authApi';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { useState } from 'react';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import handleErrorAsync from '../../../utils/handleCatchAsync';
import ToLogin from './ToLogin';

function Step2({ setStep, otp, setOtp }) {
    const [errorMsg, setErrorMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async () => {
        if (`otp`.length > 0) {
            setIsLoading(true);
            try {
                const res = await authApi.verifyResetPasswordOTP({
                    OTPCode: otp,
                });
                if ((res.status = 200)) setStep();
            } catch (error) {
                console.log(error);
                handleErrorAsync(error, setErrorMsg);
            }
            setIsLoading(false);
        }
    };

    const wrapperEnter = (e) => {
        if (e.key === 'Enter') handleSubmit();
    };

    return (
        <div
            onKeyDown={wrapperEnter}
            className="flex-1 flex flex-col gap-10 justify-center"
        >
            <div className="flex flex-col gap-2">
                <span className="my-0 mx-auto">
                    <FontAwesomeIcon
                        className="text-2xl p-4 border rounded-lg"
                        icon={faEnvelope}
                    />
                </span>
                <p className="text-xl font-semibold"> Xác thực OTP</p>
                <span className="text-sm text-gray-500">
                    Chúng tôi đã gửi mã OTP về email của bạn.
                </span>
            </div>
            {errorMsg && (
                <div className="border p-1 border-red-500">
                    <span className="text-sm text-red-500"> {errorMsg}</span>
                </div>
            )}
            <input
                autoFocus
                value={otp}
                onChange={setOtp}
                type="text"
                placeholder="Nhập mã OTP"
                className="p-3 w-full bg-slate-200 focus-within:outline-slate-600 rounded-lg"
            />

            <div className="flex flex-col gap-5">
                <div className="flex justify-center gap-1">
                    <span className="text-sm text-gray-500">
                        Không nhận được mã xác nhận?
                    </span>
                    <span className="text-sm font-semibold text-violet-600">
                        Gửi lại
                    </span>
                </div>
                <button
                    className={`bg-violet-500 p-2 w-full text-white rounded-lg ${
                        isLoading && 'opacity-60 cursor-not-allowed'
                    }`}
                    onClick={handleSubmit}
                >
                    {isLoading ? (
                        <FontAwesomeIcon
                            className="animate-spin pointer-events-none"
                            icon={faCircleNotch}
                        />
                    ) : (
                        'Tiếp tục'
                    )}
                </button>
            </div>
            <ToLogin />
        </div>
    );
}

export default Step2;
