import React from 'react';
import styles from './CircleLoader.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

function CircleLoader() {
    return (
        <div className={styles.wrapper}>
            <FontAwesomeIcon icon={faCircleNotch} />
        </div>
    );
}

export default CircleLoader;
