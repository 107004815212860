import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    eachDayOfInterval,
    eachMonthOfInterval,
    format,
    lastDayOfMonth,
    lastDayOfYear,
} from 'date-fns';
import orderApi from '../../../api/orderApi';
import Bigchart from './Bigchart';
import OrderToday from './OrderToday';
import MonthTotal from './MonthTotal';
import ReturnedPercent from './ReturnedPercent';
import TopReturning from './TopReturning';
import { vi } from 'date-fns/locale';

function Overview() {
    const [orderStats, setOrderStats] = useState([]);
    const [orderToday, setOrderToday] = useState([]);
    const [orderThisMonth, setOrderThisMonth] = useState();
    const [returning, setReturning] = useState();
    const [top10Returning, setTop10Returning] = useState([]);
    const params = useParams();
    const [statsTimeOption, setStatsTimeOption] = useState({
        month: format(new Date(), 'yyyy-MM'),
        year: undefined,
        week: undefined,
    });

    let labels = [];
    let dataStats = [];
    if (statsTimeOption.month) {
        labels = eachDayOfInterval({
            start: new Date(statsTimeOption.month + '-01'),
            end: lastDayOfMonth(new Date(statsTimeOption.month)),
        }).map((el) => format(el, 'yyyy-MM-dd'));
    }

    if (statsTimeOption.year) {
        labels = eachMonthOfInterval({
            start: new Date(statsTimeOption.year, 0, 1),
            end: lastDayOfYear(new Date(statsTimeOption.year, 0, 1)),
        }).map((el) => format(el, 'yyyy-MM'));
    }

    dataStats = labels.reduce((acc, cur) => {
        if (new Date(cur) > new Date()) return acc;
        let day = { packed: 0, returned: 0, cancelled: 0 };
        orderStats.forEach((order) => {
            if (order._id === cur) day = order;
        });
        acc.push(day);
        return acc;
    }, []);

    useEffect(() => {
        const fetchOrderStats = async () => {
            const timeOption = Object.keys(statsTimeOption).filter(
                (el) => statsTimeOption[el]
            )[0];

            const queryString = `${timeOption}=${statsTimeOption[timeOption]}`;
            try {
                const { data } = await orderApi.getStatsOrders(
                    params.shopId,
                    queryString
                );
                setOrderStats(data.data);
            } catch (error) {
                console.log(error);
            }
        };

        const fetchOrderToday = async () => {
            try {
                const { data } = await orderApi.orderTodayStats(params.shopId);
                setOrderToday(data.data);
            } catch (error) {
                console.log(error);
            }
        };

        fetchOrderToday();
        fetchOrderStats();
    }, [params, statsTimeOption]);

    useEffect(() => {
        const fetchOrderOfThisMonth = async () => {
            try {
                const { data } = await orderApi.ordersThisMonthStats(
                    params.shopId
                );
                setOrderThisMonth(data.data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchOrderOfThisMonth();
    }, [params]);

    useEffect(() => {
        const fetchReturningStats = async () => {
            try {
                const { data } = await orderApi.returningStats(params.shopId);
                setReturning(data.data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchReturningStats();
    }, [params]);

    useEffect(() => {
        const fetchTop10Returning = async () => {
            try {
                const { data } = await orderApi.top10returning(params.shopId);
                setTop10Returning(data.data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchTop10Returning();
    }, [params]);
    const datasets = [
        {
            label: 'packed',
            data: dataStats.map((el) => el.packed),
            borderColor: '#7c3aed',
            backgroundColor: '#7c3aed',
            borderWidth: '1.5',
            tension: 0.1,
        },
        {
            label: 'cancelled',
            data: dataStats.map((el) => el.cancelled),
            borderColor: '#ea580c',
            backgroundColor: '#ea580c',
            borderWidth: '1.5',
            tension: 0.1,
        },
    ];
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false, // Remove x-axis grid lines
                },
            },
            y: {
                beginAtZero: true,
            },
        },
        elements: {
            point: {
                radius: function (context) {
                    return context.hovered ? 4 : 0;
                },
            },
        },
        plugins: {
            tooltip: {
                intersect: false,
                mode: 'index',
                callbacks: {
                    label: function (context) {
                        if (context.parsed.y !== null) {
                            return context.parsed.y;
                        }
                        return null;
                    },
                },
            },
        },
        hover: {
            mode: 'nearest',
            intersect: true,
        },
    };

    const showLabels = (labels) => {
        if (statsTimeOption.month) {
            return labels.map((el) => format(new Date(el), 'dd/MM'));
        }
        if (statsTimeOption.year) {
            return labels.map((el) =>
                format(new Date(el), 'MM', {
                    locale: vi,
                })
            );
        }
    };

    const data = {
        labels: showLabels(labels),
        datasets,
    };

    return (
        <div className="w-full h-full overflow-auto">
            <div className="w-full h-full grid grid-cols-3 gap-5 min-w-[1080px]">
                <div className="col-span-2 grid grid-rows-6 overflow-auto gap-5 pt-5 px-5">
                    <Bigchart
                        data={data}
                        options={options}
                        timeSelect={statsTimeOption}
                        setTimeSelect={setStatsTimeOption}
                    />
                    <OrderToday orderToday={orderToday} />
                </div>
                <div className="overflow-auto flex flex-col gap-5 col-span-1 bg-white rounded-lg drop-shadow-lg">
                    <MonthTotal orderThisMonth={orderThisMonth} />
                    <ReturnedPercent
                        returning={returning}
                        orderThisMonth={orderThisMonth}
                    />
                    <TopReturning top10Returning={top10Returning} />
                </div>
            </div>
        </div>
    );
}

export default Overview;
