import React, { useRef, useState } from 'react';
import Calendar from './Calendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCalendarAlt,
    faXmarkCircle,
} from '@fortawesome/free-solid-svg-icons';
import { format, startOfToday, startOfYesterday } from 'date-fns';

function DateRangePicker({ onChange, dateRange }) {
    const TODAY = startOfToday();
    const startDateRef = useRef(dateRange.startDate);
    const [openCalendar, setOpenCalendar] = useState(false);
    const [calendar, setCalendar] = useState({
        right: {
            month: TODAY.getMonth(),
            year: TODAY.getFullYear(),
        },
        left: {
            month: TODAY.getMonth() === 0 ? 11 : TODAY.getMonth() - 1,
            year:
                TODAY.getMonth() === 0
                    ? TODAY.getFullYear() - 1
                    : TODAY.getFullYear(),
        },
    });

    const [hoverDate, setHoverDate] = useState(null);

    const handleSelectDate = (e) => {
        const value = e.target.dataset.value * 1;
        if (dateRange.endDate) {
            return onChange((prev) => ({
                ...prev,
                startDate: new Date(value).getTime(),
                endDate: null,
            }));
        }
        if (dateRange.startDate) {
            if (value > dateRange.startDate) {
                onChange((prev) => ({
                    ...prev,
                    endDate: new Date(value).getTime(),
                }));
                setHoverDate(null);
            } else {
                onChange((prev) => ({
                    ...prev,
                    endDate: prev.startDate,
                    startDate: new Date(value).getTime(),
                }));
            }
            return setOpenCalendar(false);
        }

        onChange((prev) => ({
            ...prev,
            startDate: new Date(value).getTime(),
        }));
    };

    const handleHoverDate = (e) => {
        const value = e.target.dataset.value * 1;

        if (dateRange.startDate && !dateRange.endDate) {
            setHoverDate(new Date(value).getTime());
        }
    };

    const handeleOnChevron = (e, name) => {
        const value = e.target.dataset.value;

        if (value === 'left') {
            if (
                name === 'right' &&
                new Date(calendar.right.year, calendar.right.month - 1) <=
                    new Date(calendar.left.year, calendar.left.month)
            )
                return;

            if (calendar[name].month === 0) {
                return setCalendar((prev) => ({
                    ...prev,
                    [name]: {
                        month: 11,
                        year: prev[name].year - 1,
                    },
                }));
            }

            setCalendar((prev) => ({
                ...prev,
                [name]: {
                    ...prev[name],
                    month: prev[name].month - 1,
                },
            }));
        }

        if (value === 'right') {
            if (
                name === 'left' &&
                new Date(calendar.right.year, calendar.right.month) <=
                    new Date(calendar.left.year, calendar.left.month + 1)
            )
                return;

            if (calendar[name].month === 11) {
                return setCalendar((prev) => ({
                    ...prev,
                    [name]: {
                        month: 0,
                        year: prev[name].year + 1,
                    },
                }));
            }

            setCalendar((prev) => ({
                ...prev,
                [name]: {
                    ...prev[name],
                    month: prev[name].month + 1,
                },
            }));
        }
    };

    const handleOpenCalendar = () => {
        setOpenCalendar((prev) => !prev);
    };

    const handleRemoveDateRange = (e) => {
        e.stopPropagation();
        onChange((prev) => ({
            ...prev,
            startDate: startDateRef.current,
            endDate: TODAY.getTime(),
        }));
        setHoverDate(null);
    };

    const handleChooseDateRange = (e) => {
        const value = e.target.dataset.value;
        switch (value) {
            case 'today':
                onChange((prev) => ({
                    ...prev,
                    startDate: TODAY.getTime(),
                    endDate: TODAY.getTime(),
                }));
                setOpenCalendar(false);
                break;
            case 'yesterday':
                onChange((prev) => ({
                    ...prev,
                    startDate: startOfYesterday().getTime(),
                    endDate: startOfYesterday().getTime(),
                }));
                setOpenCalendar(false);
                break;
            case 'last7days':
                onChange((prev) => ({
                    ...prev,
                    startDate: startOfToday() - 7 * 24 * 60 * 60 * 1000,
                    endDate: TODAY.getTime(),
                }));
                setOpenCalendar(false);
                break;
            case 'last30days':
                onChange((prev) => ({
                    ...prev,
                    startDate: startOfToday() - 30 * 24 * 60 * 60 * 1000,
                    endDate: TODAY.getTime(),
                }));
                setOpenCalendar(false);
                break;

            default:
                break;
        }
    };

    const handleSelectMonth = (e, name) => {
        const value = e.target.dataset.value * 1;
        console.log(value, name);
        setCalendar((prev) => ({
            ...prev,
            [name]: {
                ...prev[name],
                month: value,
            },
        }));
    };

    const handleSelectYear = (e, name) => {
        const value = e.target.dataset.value * 1;
        console.log(value, name);
        setCalendar((prev) => ({
            ...prev,
            [name]: {
                ...prev[name],
                year: value,
            },
        }));
    };

    return (
        <div
            tabIndex={1}
            onClick={handleOpenCalendar}
            onBlur={() => setOpenCalendar(false)}
            className="h-full w-full relative z-50"
        >
            <div className="flex cursor-default select-none relative items-center justify-between h-full p-2">
                <span className="text-gray-500 text-xl flex items-center justify-center">
                    <FontAwesomeIcon icon={faCalendarAlt} />
                </span>
                <div className="flex-1 flex items-center justify-center gap-3">
                    <span>
                        {dateRange.startDate
                            ? format(dateRange.startDate, 'dd/MM')
                            : '---'}
                    </span>
                    <span> - </span>
                    <span>
                        {dateRange.endDate
                            ? format(dateRange.endDate, 'dd/MM')
                            : '---'}
                    </span>
                </div>
                {dateRange.startDate && (
                    <span
                        className={`text-gray-300 absolute top-1/2 -translate-y-1/2 right-[8px] hover:text-slate-500`}
                        onClick={handleRemoveDateRange}
                    >
                        <FontAwesomeIcon
                            className="pointer-events-none"
                            icon={faXmarkCircle}
                        />
                    </span>
                )}
            </div>
            <div
                onClick={(e) => e.stopPropagation()}
                className={`flex gap-1 absolute top-full mt-[4px] right-0 z-20 p-2 bg-white rounded-lg shadow-lg ${
                    !openCalendar && 'hidden'
                }`}
            >
                <ul className="w-40 text-sm py-4 cursor-default select-none">
                    <li
                        onClick={handleChooseDateRange}
                        data-value="today"
                        className="p-1 hover:bg-slate-200 rounded-lg"
                    >
                        Hôm nay
                    </li>
                    <li
                        onClick={handleChooseDateRange}
                        data-value="yesterday"
                        className="p-1 hover:bg-slate-200 rounded-lg"
                    >
                        Hôm qua
                    </li>
                    <li
                        onClick={handleChooseDateRange}
                        data-value="last7days"
                        className="p-1 hover:bg-slate-200 rounded-lg"
                    >
                        7 ngày vừa qua
                    </li>
                    <li
                        onClick={handleChooseDateRange}
                        data-value="last30days"
                        className="p-1 hover:bg-slate-200 rounded-lg"
                    >
                        30 ngày vừa qua
                    </li>
                </ul>
                <div className="flex gap-1">
                    <Calendar
                        setYear={handleSelectYear}
                        setMonth={handleSelectMonth}
                        hoverDate={hoverDate}
                        isToday={TODAY.getTime()}
                        name="left"
                        yearRight={calendar.right.year}
                        monthRight={calendar.right.month}
                        onChevron={handeleOnChevron}
                        onHoverDate={handleHoverDate}
                        onSelectDate={handleSelectDate}
                        dateRange={dateRange}
                        month={calendar.left.month}
                        year={calendar.left.year}
                    />

                    <Calendar
                        setYear={handleSelectYear}
                        setMonth={handleSelectMonth}
                        hoverDate={hoverDate}
                        isToday={TODAY.getTime()}
                        name="right"
                        yearLeft={calendar.left.year}
                        monthLeft={calendar.left.month}
                        onChevron={handeleOnChevron}
                        onHoverDate={handleHoverDate}
                        onSelectDate={handleSelectDate}
                        dateRange={dateRange}
                        month={calendar.right.month}
                        year={calendar.right.year}
                    />
                </div>
            </div>
        </div>
    );
}

export default DateRangePicker;
