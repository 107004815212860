import React, { useEffect, useState } from 'react';
import usersApi from '../../../api/userApi';
import shopApi from '../../../api/shopApi';
import { useParams } from 'react-router-dom';
import ShopDetail from './ShopDetail';
import ShopOwner from './ShopOwner';
import handleErrorAsync from '../../../utils/handleCatchAsync';

function ShopInfo() {
    const [openEdit, setOpenEdit] = useState({
        shop: false,
        owner: false,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [newOwner, setNewOwner] = useState('');
    const [shopInfo, setShopInfo] = useState({});
    const [errorMsg, setErrorMsg] = useState('');
    const params = useParams();

    useEffect(() => {
        const fetchShop = async () => {
            try {
                const { data } = await shopApi.getShopById(params.shopId);
                setShopInfo(data.shop);
            } catch (error) {
                console.log(error);
            }
        };
        fetchShop();
    }, [params]);

    const handleOpenEdit = (e) => {
        const value = e.target.dataset.value;
        setOpenEdit((prev) => {
            let next = { ...prev };
            console.log(next);
            Object.keys(next).forEach((el) => (next[el] = el === value));
            return next;
        });
    };

    const handleCloseEdit = () => {
        setOpenEdit({
            owner: false,
            shop: false,
        });
        setNewOwner(null);
        setErrorMsg('');
    };

    let typingTimer;
    const delayActionTime = 1000;
    const handleTypingDone = async (e) => {
        clearTimeout(typingTimer);
        if (e.target.value) {
            typingTimer = setTimeout(async () => {
                setIsLoading(true);
                try {
                    const response = await usersApi.getUserByEmail(
                        e.target.value
                    );

                    if (response.status === 204) setNewOwner(undefined);

                    if (response.status === 200)
                        setNewOwner(response.data.data);
                } catch (error) {
                    console.log(error);
                }
                setIsLoading(false);
            }, delayActionTime);
        }
    };

    const handleChangeOwner = async () => {
        if (newOwner) {
            try {
                await shopApi.changeOwner(params.shopId, newOwner._id);
                setShopInfo((prev) => ({ ...prev, owner: newOwner }));
                setNewOwner('');
                handleCloseEdit();
            } catch (error) {
                console.log(error);
                handleErrorAsync(error, setErrorMsg);
            }
        }
    };

    const handleUpdateShop = async (shopname, logo) => {
        const form = new FormData();
        if (shopname) form.append('shopname', shopname);
        if (logo) form.append('image', logo);

        setIsLoading(true);
        try {
            const { data } = await shopApi.updateShop(params.shopId, form);
            setShopInfo(data.data);
            handleCloseEdit();
        } catch (error) {
            console.log(error);
            handleErrorAsync(error, setErrorMsg);
        }
        setIsLoading(false);
    };

    return (
        <div className="flex-1 p-5 flex flex-col gap-5">
            <ShopDetail
                shopInfo={shopInfo}
                handleCloseEdit={handleCloseEdit}
                handleOpenEdit={handleOpenEdit}
                openEdit={openEdit}
                handleUpdateShop={handleUpdateShop}
                errorMsg={errorMsg}
                isLoading={isLoading}
            />
            <ShopOwner
                openEdit={openEdit}
                shopInfo={shopInfo}
                handleChangeOwner={handleChangeOwner}
                handleCloseEdit={handleCloseEdit}
                handleOpenEdit={handleOpenEdit}
                handleTypingDone={handleTypingDone}
                isLoading={isLoading}
                newOwner={newOwner}
            />
        </div>
    );
}

export default ShopInfo;
