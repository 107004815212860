import { format } from 'date-fns';
import React from 'react';
import convertOrderStatus from '../../../utils/convertOrderStatus';
import Notes from '../OrderManager/Notes';

function ReturnedManagerTable({ orderList }) {
    return (
        <div className="overflow-auto">
            <table className="w-full">
                <thead className="sticky top-0 z-10  bg-zinc-50 text-slate-500">
                    <tr className="grid gap-2 grid-cols-12 text-left border-b-[0.5px] text-sm">
                        <th className="p-2 col-span-3 sticky left-0 bg-zinc-50">
                            Mã vận đơn
                        </th>
                        <th className="col-span-2 p-2">Nhà vận chuyển</th>
                        <th className="col-span-2 p-2">Trạng thái</th>
                        <th className="col-span-2 p-2">Ngày hoàn</th>
                        <th className="col-span-2 p-2">Ghi chú</th>
                    </tr>
                </thead>
                <tbody>
                    {orderList.length > 0 &&
                        orderList.map((el, index) => (
                            <tr
                                key={index}
                                className="grid gap-2 grid-cols-12 text-left border-b-[1px] text-sm hover:bg-slate-200"
                            >
                                <td className="p-2 whitespace-nowrap overflow-auto col-span-3 font-semibold">
                                    {el.orderNumber.toUpperCase()}
                                </td>
                                <td className="p-2 whitespace-nowrap overflow-auto col-span-2">
                                    {el.shippingUnit.name}
                                </td>
                                <td className="p-2 whitespace-nowrap overflow-auto col-span-2">
                                    <span className="bg-green-100 text-green-600 px-1 rounded-xl">
                                        {convertOrderStatus(el.status)}
                                    </span>
                                </td>
                                <td className="p-2 whitespace-nowrap overflow-auto col-span-2">
                                    {format(
                                        new Date(el.returnedObj.returnedAt),
                                        'dd-MM-yyyy HH:mm:ss'
                                    )}
                                </td>
                                <td className="p-2 whitespace-nowrap overflow-auto col-span-3">
                                    <Notes notes={el.notes} />
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>
    );
}

export default ReturnedManagerTable;
