import { faPlus, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import shopApi from '../../../api/shopApi';
import Search from '../../../components/Search';
import usersApi from '../../../api/userApi';

function StaffSetting() {
    const shopInfo = useOutletContext();
    const [users, setUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [sugList, setSugList] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const params = useParams();

    const notifySuccess = () => toast.success('Lưu thành công');
    const notifyError = () => toast.error('Lưu thất bại');
    const usersHaveChange = users.some((el) => el.remove || el.newRole);

    const handleRemoveUser = (id) => {
        setUsers((prev) =>
            prev.map((el) => ({ ...el, remove: el._id === id }))
        );
    };

    const handleChangeRole = (value, id) => {
        setUsers((prev) =>
            prev.map((el) => {
                if (el._id === id) return { ...el, newRole: value };
                return el;
            })
        );
    };

    const handleCancell = () => {
        setUsers((prev) =>
            prev.map((el) => ({ ...el, remove: false, newRole: null }))
        );
    };

    const handleSave = async () => {
        const request = users.map((el) => {
            if (el.remove) {
                return shopApi.removeUserOnShop(params.shopId, el.uid._id);
            }
            if (el.newRole) {
                return shopApi.changeRoleUser(params.shopId, el.uid._id, {
                    role: el.newRole,
                });
            }
            return null;
        });
        Promise.all(request)
            .then(async () => {
                try {
                    const { data } = await shopApi.getShopById(params.shopId);
                    setUsers(
                        data.shop.users.map((el) => ({
                            ...el,
                            remove: false,
                            newRole: null,
                        }))
                    );
                } catch (error) {
                    console.log(error);
                }
                notifySuccess();
            })
            .catch((error) => {
                console.log(error);
                notifyError();
            });
    };

    const handleAddUser = async () => {
        try {
            const { data } = await shopApi.addUserToShop(
                params.shopId,
                sugList._id
            );
            setUsers(data.data.users);
            setSearchTerm('');
            setSugList({});
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        setSugList([]);
        const fetchUser = async () => {
            if (searchTerm.trim()) {
                setIsLoading(true);
                try {
                    const response = await usersApi.getUserByEmail(searchTerm);
                    console.log(response);
                    response.status === 200 && setSugList(response.data.data);
                    response.status === 204 && setSugList([]);
                } catch (error) {
                    console.log(error);
                }
                setIsLoading(false);
            }
        };
        fetchUser();
    }, [searchTerm]);

    useEffect(() => {
        if (shopInfo)
            setUsers(
                shopInfo.users.map((el) => ({
                    ...el,
                    newRole: null,
                    remove: false,
                }))
            );
    }, [shopInfo]);

    return (
        <div className="flex flex-col gap-5">
            <ToastContainer />
            <div className="flex h-9 gap-4">
                <div className="flex-1">
                    <Search
                        isLoading={isLoading}
                        onSelect={handleAddUser}
                        onChange={setSearchTerm}
                        sugList={sugList.name ? [sugList.name] : []}
                        value={searchTerm}
                    />
                </div>
                <button
                    onClick={handleAddUser}
                    className="bg-slate-800 text-white h-full aspect-square flex items-center justify-center rounded-lg"
                >
                    <FontAwesomeIcon icon={faPlus} />
                </button>
            </div>
            <ul className="flex flex-col gap-2">
                {users &&
                    users.map((el, index) => (
                        <li
                            key={index}
                            className={`p-2 bg-white border rounded-lg cursor-default hover:bg-slate-100 ${
                                el.remove && 'hidden'
                            }`}
                        >
                            <div className="flex justify-between items-center gap-4">
                                <div className="flex flex-[2] items-center gap-4">
                                    <div className="w-6 h-6 overflow-auto flex items-center justify-center bg-violet-100 rounded-full ">
                                        {el.uid.avatar ? (
                                            <img
                                                alt="avatar"
                                                src={`${process.env.REACT_APP_API_URL}/img/users/${el.uid.avatar}`}
                                            />
                                        ) : (
                                            <FontAwesomeIcon
                                                className="text-sm text-slate-600"
                                                icon={faUser}
                                            />
                                        )}
                                    </div>
                                    <span>{el.uid.name}</span>
                                </div>
                                <div className="flex-1">
                                    {el.role !== 'owner' ? (
                                        <select
                                            onChange={(e) =>
                                                handleChangeRole(
                                                    e.target.value,
                                                    el._id
                                                )
                                            }
                                            value={el.newRole || el.role}
                                            className="bg-transparent ml-[-6px]"
                                        >
                                            <option value="user">
                                                Nhân viên
                                            </option>
                                            <option value="manager">
                                                Quản lý
                                            </option>
                                        </select>
                                    ) : (
                                        <span className=" text-violet-600">
                                            Chủ sở hữu
                                        </span>
                                    )}
                                </div>

                                <div className="flex-1 flex justify-end">
                                    {el.role !== 'owner' ? (
                                        <FontAwesomeIcon
                                            onClick={() =>
                                                handleRemoveUser(el._id)
                                            }
                                            className="text-sm text-slate-500"
                                            icon={faXmark}
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </li>
                    ))}
            </ul>
            {usersHaveChange && (
                <div className="flex justify-end gap-3 h-8">
                    <button
                        className="bg-violet-500 rounded-lg text-white w-16 active:opacity-60"
                        onClick={handleSave}
                    >
                        Lưu
                    </button>
                    <button
                        className="bg-white border rounded-lg border-solid border-slate-900 w-16 active:opacity-60"
                        onClick={handleCancell}
                    >
                        Huỷ
                    </button>
                </div>
            )}
        </div>
    );
}

export default StaffSetting;
