import {
    faFileExcel,
    faXmarkCircle,
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Button from './Button/Button';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

function UploadOrderForm({
    file,
    onClose,
    onSubmit,
    message,
    isLoading,
    title,
}) {
    if (file)
        return (
            <div
                onClick={(e) => e.stopPropagation()}
                className="w-96 p-10 bg-white animate-zoomIn relative rounded-lg shadow-lg flex flex-col gap-5"
            >
                <div className="font-semibold text-center">
                    {title || 'Tải lên'}
                </div>
                {message && <span className="italic">{message}</span>}
                {isLoading ? (
                    <div className="flex flex-col items-center justify-center gap-3">
                        <span className="text-5xl">
                            <FontAwesomeIcon
                                className="animate-spin"
                                icon={faCircleNotch}
                            />
                        </span>
                        <span>
                            Đang tải đơn hàng, quá trình có thể sẽ mất 1-2 phút.
                            Giữ trình duyệt mở cho tới khi hoàn thành.
                        </span>
                    </div>
                ) : (
                    <div className="flex items-center gap-3 p-2 border-dashed border-2  text-ellipsis whitespace-nowrap overflow-hidden">
                        <span className="text-5xl text-slate-700">
                            <FontAwesomeIcon icon={faFileExcel} />
                        </span>
                        <div>
                            <span className="text-sm">{file.name}</span>
                            <div>
                                <span className="text-xs bg-violet-200 px-1 rounded-lg">
                                    {`${file.size} bytes`}
                                </span>
                            </div>
                        </div>
                    </div>
                )}
                <div className="flex gap-2">
                    <Button disable={isLoading} rounder onClick={onSubmit}>
                        <span>Tải lên</span>
                    </Button>
                </div>
                {!isLoading && (
                    <span
                        onClick={onClose}
                        className="absolute top-1 right-1 flex items-center justify-center text-slate-400 hover:text-slate-800"
                    >
                        <FontAwesomeIcon
                            className="pointer-events-none"
                            icon={faXmarkCircle}
                        />
                    </span>
                )}
            </div>
        );
}

export default UploadOrderForm;
