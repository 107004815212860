import axiosClient from './axiosClient';

const shopApi = {
    getShop: async () => {
        const baseURL = 'shop?sort=-_id';
        return await axiosClient.get(baseURL);
    },
    getShopById: async (shopId) => {
        const baseURL = `shop/${shopId}`;
        return await axiosClient.get(baseURL);
    },
    createShop: async (data) => {
        const baseURL = 'shop';
        return await axiosClient.post(baseURL, data);
    },
    updateShop: async (id, data) => {
        const baseURL = `shop/${id}/update-shop`;
        return await axiosClient.patch(baseURL, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },
    deleteShop: async (id) => {
        const baseURL = `shop/${id}/delete`;
        return await axiosClient.delete(baseURL);
    },
    changeOwner: async (shopId, uid) => {
        const baseURL = `shop/${shopId}/users/change-owner`;
        return await axiosClient.patch(baseURL, { uid });
    },
    addUserToShop: (shopId, uid) => {
        const baseURL = `shop/${shopId}/users`;
        return axiosClient.post(baseURL, { uid });
    },
    removeUserOnShop: async (shopId, uid) => {
        const baseURL = `shop/${shopId}/users/${uid}`;
        return await axiosClient.delete(baseURL);
    },
    changeRoleUser: (shopId, uid, role) => {
        const baseURL = `shop/${shopId}/users/${uid}`;
        return axiosClient.patch(baseURL, role);
    },
    exportOrder: async (id, filter) => {
        const baseURL = `shop/${id}/orders/export?${filter}`;
        return await axiosClient.get(baseURL, {
            responseType: 'arraybuffer',
        });
    },
    examplePacked: async () => {
        const baseURL = 'shop/example-packed';
        return await axiosClient.get(baseURL);
    },
    exampleReturned: () => {
        const baseURL = 'shop/example-returned';
        return axiosClient.get(baseURL);
    },
};

export default shopApi;
