import React, { useState } from 'react';

import authApi from '../../../api/authApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircleNotch,
    faFingerprint,
} from '@fortawesome/free-solid-svg-icons';
import Validator from '../../../utils/Validator';
import handleErrorAsync from '../../../utils/handleCatchAsync';
import ToLogin from './ToLogin';

function Step1({ setStep }) {
    const [email, setEmail] = useState('');
    const [checkEmail, setCheckEmail] = useState(true);
    const [errorMsg, setErrorMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleChangeInput = (e) => {
        setCheckEmail(true);
        setEmail(e.target.value);
    };

    const handleSubmit = async () => {
        if (new Validator(email).isRequired().isEmail().valid && !isLoading) {
            setIsLoading(true);
            try {
                const res = await authApi.forgotPassword({
                    email: email,
                });
                if (res.status === 200) setStep();
            } catch (error) {
                console.log(error);
                handleErrorAsync(error, setErrorMsg);
            }
            setIsLoading(false);
        }
    };

    const handleBlur = () => {
        setCheckEmail(new Validator(email).isEmail().isRequired().valid);
    };

    const wrapperEnter = (e) => {
        if (e.key === 'Enter') handleSubmit();
    };
    return (
        <div
            onKeyDown={wrapperEnter}
            className="flex flex-1 justify-center flex-col gap-10"
        >
            <div className="flex flex-col gap-2">
                <span className="my-0 mx-auto">
                    <FontAwesomeIcon
                        className="text-2xl p-4 border rounded-lg"
                        icon={faFingerprint}
                    />
                </span>
                <p className="text-xl font-semibold">Bạn quên mật khẩu?</p>
                <span className="text-sm text-gray-500">
                    Đừng lo! Chúng ta cùng lấy lại mật khẩu ngay thôi.
                </span>
            </div>
            {errorMsg && (
                <div className="w-full text-sm text-red-500 border p-1 border-red-500">
                    <span className="w-full">{errorMsg}</span>
                </div>
            )}
            <div className="flex flex-col gap-1">
                <label htmlFor="email">Email</label>
                <span
                    className={`text-xs text-red-500 ${checkEmail && 'hidden'}`}
                >
                    *Vui lòng nhập email của bạn
                </span>
                <input
                    disabled={isLoading}
                    onBlur={handleBlur}
                    onChange={handleChangeInput}
                    id="email"
                    type="email"
                    placeholder="nguyenvana@email.com"
                    className={`p-3 bg-slate-200 rounded-lg outline-slate-600 ${
                        !checkEmail && 'animate-shake border border-red-500'
                    }`}
                />
            </div>
            <button
                className={`bg-violet-500 p-2 text-white rounded-lg ${
                    isLoading && 'opacity-60 cursor-not-allowed'
                }`}
                onClick={handleSubmit}
            >
                {isLoading ? (
                    <FontAwesomeIcon
                        className="animate-spin"
                        icon={faCircleNotch}
                    />
                ) : (
                    'Đặt lại mật khẩu'
                )}
            </button>
            <ToLogin />
        </div>
    );
}

export default Step1;
