import React, { useRef, useState } from 'react';
import Button from '../../components/Button/Button';
import shopApi from '../../api/shopApi';
import handleErrorAsync from '../../utils/handleCatchAsync';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faStore } from '@fortawesome/free-solid-svg-icons';

function EditShop({ shop, setOpenModal, setShops }) {
    const [shopname, setShopname] = useState(shop.shopname);
    const [inValid, setInValid] = useState(false);
    const [errMsg, setErrMsg] = useState();
    const [image, setImage] = useState();
    const [onHover, setOnHover] = useState(false);

    const inputRef = useRef();
    const handleOnChange = (e) => {
        setInValid(false);
        setShopname(e.target.value);
    };

    const handleClose = () => {
        setOpenModal(false);
        setInValid(false);
        setErrMsg('');
        setShopname(shop.shopname);
        setImage(null);
        inputRef.current.value = null;
    };

    const handleEdit = async () => {
        if (shopname === '' || shopname.length < 4) {
            return setInValid(true);
        }
        try {
            const form = new FormData();
            form.append('shopname', shopname);
            if (image) form.append('image', image);
            const { data } = await shopApi.updateShop(shop._id, form);
            setOpenModal(false);
            setShops((prev) =>
                prev.map((shop) =>
                    shop._id === data.data._id ? data.data : shop
                )
            );
        } catch (error) {
            handleErrorAsync(error, setErrMsg);
        }
    };

    const handleBrowse = () => {
        inputRef.current.click();
    };

    const handleSetFile = (e) => {
        setImage(e.target.files[0]);
    };

    let urlImg;
    if (image) urlImg = URL.createObjectURL(image);
    return (
        <div className="px-5 py-8 bg-white rounded-lg w-80 flex flex-col justify-center items-center gap-4">
            {errMsg && (
                <span className="text-center text-red-500 border rounded border-red-500 text-sm">
                    {errMsg}
                </span>
            )}
            <span>Thay đổi thông tin</span>

            <div
                onMouseOver={() => setOnHover(true)}
                onMouseLeave={() => setOnHover(false)}
                onClick={handleBrowse}
                className="h-20 w-20 bg-slate-800 rounded-full flex justify-center items-center overflow-hidden relative hover:opacity-60"
            >
                {onHover && (
                    <span className="text-white text-2xl absolute z-20">
                        <FontAwesomeIcon icon={faCamera} />
                    </span>
                )}
                {urlImg ? (
                    <img
                        src={urlImg}
                        className="h-full object-cover"
                        alt="logo"
                    />
                ) : shop.logo ? (
                    <img
                        alt="logo"
                        src={`${process.env.REACT_APP_API_URL}/img/shops/${shop.logo}`}
                    />
                ) : (
                    <FontAwesomeIcon
                        className={`text-2xl text-white ${onHover && 'hidden'}`}
                        icon={faStore}
                    />
                )}
                <input
                    hidden
                    onChange={handleSetFile}
                    type="file"
                    accept="image/*"
                    ref={inputRef}
                />
            </div>

            <div className="w-full">
                <span
                    className={`${!inValid && 'hidden'} text-sm text-red-500`}
                >
                    * Tối thiểu 4 ký tự
                </span>
                <input
                    className={`w-full p-2 bg-slate-200 rounded-lg outline-none ${
                        inValid && 'animate-shake'
                    }`}
                    placeholder="Nhập tên shop của bạn"
                    value={shopname}
                    onChange={handleOnChange}
                />
            </div>
            <div className="w-full flex gap-3">
                <Button onClick={handleEdit} rounder>
                    Xác nhận
                </Button>
                <Button onClick={handleClose} border rounder>
                    Huỷ
                </Button>
            </div>
        </div>
    );
}

export default EditShop;
