import { faInfinity } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const convertRatio = (ratio) => {
    if (isNaN(ratio)) return '---';
    if (ratio === Infinity) return <FontAwesomeIcon icon={faInfinity} />;
    return `${Math.round(ratio * 10) / 10}%`;
};

export default convertRatio;
