import React, { useEffect, useState } from 'react';
import { Outlet, redirect } from 'react-router-dom';
import SideBar from '../../components/SideBar/SideBar';
import shippingUnitApi from '../../api/shippingUnitApi';
import usersApi from '../../api/userApi';

function DashBoardMain() {
    const [shippingUnit, setShippingUnit] = useState([]);
    const [userInfo, setUserInfo] = useState();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await shippingUnitApi.getShippingUnits();
                const user = await usersApi.getMe();
                setShippingUnit(data.data);
                setUserInfo(user.data.user);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, []);
    return (
        <div className="h-screen w-full flex justify-between bg-zinc-50">
            <div className="w-56 z-50">
                <SideBar userInfo={userInfo} />
            </div>
            <div className="flex flex-1 justify-center items-center p-10 overflow-auto">
                <Outlet context={{ shippingUnit, userInfo, setUserInfo }} />
            </div>
        </div>
    );
}

export default DashBoardMain;

DashBoardMain.loader = async () => {
    if (!localStorage.getItem('access_token')) {
        return redirect('/login');
    }
    let dataLoader = { shippingUnit: null, userInfo: null };
    try {
        const { data } = await shippingUnitApi.getShippingUnits();
        const user = await usersApi.getMe();
        dataLoader.shippingUnit = data.data;
        dataLoader.userInfo = user.data.user;
    } catch (error) {
        console.log(error);
        throw new Error('Can not load started data ');
    }

    return dataLoader;
};
