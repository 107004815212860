import React from 'react';

function FormInput({ formInput, inputValid, onBlur, onChange }) {
    const inValid = 'border-red-500';
    return (
        <div className="w-full">
            {formInput.map((item) => (
                <div key={item.id} className="flex flex-col h-20">
                    <input
                        onBlur={onBlur}
                        onChange={onChange}
                        key={item.name}
                        name={item.name}
                        type={item.type}
                        placeholder={item.placeholder}
                        className={`py-3 px-4 border outline-none rounded-md ${
                            !inputValid[item.name] && inValid
                        }`}
                    />
                    {!inputValid[item.name] && (
                        <span className="text-red-500 text-[14px]">
                            {item.errorMessage}
                        </span>
                    )}
                </div>
            ))}
        </div>
    );
}

export default FormInput;
