import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { NavLink } from 'react-router-dom';

function ToLogin() {
    return (
        <NavLink
            to="/login"
            className="flex gap-2 items-center justify-center text-sm font-semibold text-gray-500"
        >
            <FontAwesomeIcon icon={faArrowLeft} />{' '}
            <span>Trở lại đăng nhập</span>
        </NavLink>
    );
}

export default ToLogin;
