import React, { useRef, useState } from 'react';
import { format, differenceInDays } from 'date-fns';
import convertOrderStatus from '../../../utils/convertOrderStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faNoteSticky,
    faXmarkCircle,
} from '@fortawesome/free-regular-svg-icons';
import { faBoxOpen, faEllipsisV } from '@fortawesome/free-solid-svg-icons';

import Notes from './Notes';
import Modal from '../../../components/Modal/Modal';
import AddNote from './AddNote';

function OrderTable({ orderList, setOrderList }) {
    const [isScrolling, setIsScrolling] = useState(false);
    const tablleRef = useRef();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [orderEditing, setOrderEditting] = useState(null);

    const handleScroll = () => {
        setIsScrolling(tablleRef.current?.scrollLeft > 0);
    };
    const handleOpenOrderMenu = (id) => {
        setOrderList((prev) => {
            return prev.map((order) => ({
                ...order,
                isOpenMenu: order._id === id,
            }));
        });
    };

    const handleOpenNoteBox = (order) => {
        setIsOpenModal(true);
        setOrderEditting(order);
    };
    return (
        <div
            onScroll={handleScroll}
            ref={tablleRef}
            className="bg-white flex flex-col shadow-lg overflow-auto flex-1"
        >
            <table className="w-full min-w-[1024px] border-collapse cursor-default ">
                <thead className="sticky top-0 z-10  bg-zinc-50 text-slate-500">
                    <tr className="grid gap-2 grid-cols-12 text-left border-b-[0.5px] text-sm">
                        <th className="p-2 col-span-3 sticky left-0 bg-zinc-50">
                            Mã vận đơn
                        </th>
                        <th className="p-2 col-span-2">Nhà vận chuyển</th>
                        <th className="p-2 col-span-2">Ngày tạo</th>
                        <th className="p-2 col-span-2">Trạng thái</th>
                        <th className="p-2 col-span-3">Ghi chú</th>
                    </tr>
                </thead>
                <tbody>
                    {orderList.length > 0 &&
                        orderList.map((order) => (
                            <tr
                                key={order._id}
                                className="grid gap-2 grid-cols-12 text-left border-b-[1px] text-sm hover:bg-slate-200"
                            >
                                <td
                                    className={`p-2 font-semibold col-span-3 sticky left-0 z-1 ${
                                        isScrolling
                                            ? 'bg-white'
                                            : 'bg-transparent'
                                    }`}
                                >
                                    {order.orderNumber?.toUpperCase()}
                                </td>
                                <td className="p-2 col-span-2 overflow-auto">
                                    {order.shippingUnit?.name}
                                </td>
                                <td className="p-2 col-span-2">
                                    {format(
                                        new Date(order.createAt),
                                        'dd-MM-yyyy HH:mm:ss'
                                    )}
                                </td>
                                <td className={`p-2 col-span-2`}>
                                    <span
                                        className={`px-[6px] py=[4px] relative rounded-xl ${
                                            order.status === 'returned' &&
                                            'bg-green-100 text-green-700'
                                        } ${
                                            order.status === 'returning' &&
                                            'bg-red-100 text-red-700'
                                        } ${
                                            order.status === 'packed' &&
                                            'bg-orange-100 text-orange-700'
                                        }`}
                                    >
                                        {order.status === 'returning' &&
                                            differenceInDays(
                                                new Date(),
                                                new Date(
                                                    order.cancelledObj.cancelledAt
                                                )
                                            ) > 2 && (
                                                <span className="absolute flex h-4 w-4 top-[-6px] right-[-10px]">
                                                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                                                    <span className="relative inline-flex items-center justify-center text-xs rounded-full h-4 w-4 bg-red-500 text-white">
                                                        {differenceInDays(
                                                            new Date(),
                                                            new Date(
                                                                order.cancelledObj.cancelledAt
                                                            )
                                                        )}
                                                    </span>
                                                </span>
                                            )}
                                        {convertOrderStatus(order.status)}
                                    </span>
                                </td>
                                <td className="p-2 col-span-3">
                                    <div className="flex relative justify-between items-center gap-2">
                                        <Notes notes={order.notes} />
                                        <span
                                            className="relative px-1 pl-2"
                                            tabIndex={1}
                                            onMouseOver={() =>
                                                handleOpenOrderMenu(order._id)
                                            }
                                            onMouseLeave={handleOpenOrderMenu}
                                        >
                                            <FontAwesomeIcon
                                                className="pointer-events-none"
                                                icon={faEllipsisV}
                                            />
                                            <ul
                                                className={`${
                                                    !order.isOpenMenu &&
                                                    'hidden'
                                                } absolute z-10 bg-white w-28 mr-4 top-0 -right-full shadow`}
                                            >
                                                <li
                                                    onClick={() =>
                                                        handleOpenNoteBox(order)
                                                    }
                                                    className="p-1 flex gap-2 items-center hover:bg-violet-200"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faNoteSticky}
                                                    />
                                                    <span>Ghi chú</span>
                                                </li>
                                                <li className="p-1 flex gap-2 items-center hover:bg-violet-200">
                                                    <FontAwesomeIcon
                                                        icon={faXmarkCircle}
                                                    />
                                                    <span>Xoá đơn</span>
                                                </li>
                                            </ul>
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
            {orderList.length === 0 && (
                <div className="flex-1 gap-4 flex justify-center items-center">
                    <span className="text-slate-300 text-[60px]">
                        <FontAwesomeIcon icon={faBoxOpen} />
                    </span>
                </div>
            )}
            <Modal isOpen={isOpenModal}>
                <AddNote
                    setOrderList={setOrderList}
                    order={orderEditing}
                    onClose={() => setIsOpenModal(false)}
                />
            </Modal>
        </div>
    );
}

export default OrderTable;
