import React from 'react';
import Button from '../Button/Button';

function YesNo({ setOpenModal, action, yes, no, errMsg, title }) {
    return (
        <div className="animate-zoomIn flex flex-col gap-5 w-80  bg-white p-5 rounded-lg">
            {errMsg && <span className="text-red-500 text-sm">{errMsg}</span>}
            <span>{title || 'Are you sure?'}</span>
            <div className="flex gap-5">
                <Button onClick={action} rounder>
                    {yes || 'Yes'}
                </Button>
                <Button onClick={() => setOpenModal(false)} border rounder>
                    {no || 'No'}
                </Button>
            </div>
        </div>
    );
}

export default YesNo;
