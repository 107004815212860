import { differenceInDays, format } from 'date-fns';
import { NavLink, useParams } from 'react-router-dom';
import React from 'react';

function TopReturning({ top10Returning }) {
    const params = useParams();
    return (
        <div className="px-5 flex-1 flex flex-col gap-5 pb-5 overflow-auto">
            <span className="font-semibold">Top 10 đơn đang hoàn</span>
            <div className="h-full w-full overflow-auto">
                <ul className="flex flex-col gap-1">
                    {top10Returning.map((el, index) => (
                        <li key={index}>
                            <div className="flex justify-between h-10">
                                <div className="flex justify-start gap-5">
                                    <span className="flex aspect-square items-center justify-center border rounded-lg h-full">
                                        {`#${index + 1} `}
                                    </span>
                                    <span className="h-full w-20 p-2">
                                        <img
                                            className="h-full object-cover"
                                            alt="logo-ship"
                                            src={`${process.env.REACT_APP_API_URL}/img/shippingUnits/${el.shippingUnit.logo}`}
                                        />
                                    </span>
                                    <div className="flex flex-col">
                                        <span className="text-sm font-semibold">
                                            {el.orderNumber.toUpperCase()}
                                        </span>
                                        <div className="flex">
                                            <span className="px-1 rounded-lg text-xs bg-red-100 text-red-600">
                                                {format(
                                                    new Date(
                                                        el.cancelledObj?.cancelledAt
                                                    ),
                                                    'yyyy-MM-dd'
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <span
                                    className="
                flex justify-center items-center px-5"
                                >
                                    <span className="relative flex h-5 w-5">
                                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                                        <span className="relative inline-flex rounded-full h-5 w-5 bg-red-500 text-white text-sm  justify-center items-center ">
                                            {differenceInDays(
                                                new Date(),
                                                new Date(
                                                    el.cancelledObj?.cancelledAt
                                                )
                                            )}
                                        </span>
                                    </span>
                                </span>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            <NavLink
                to={`/shop/${params.shopId}/order-manager`}
                className="border rounded-lg p-2 text-center hover:bg-slate-100"
            >
                Chi tiết
            </NavLink>
        </div>
    );
}

export default TopReturning;
