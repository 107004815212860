import { Link } from 'react-router-dom';

import styles from './Button.module.css';

function Button({
    to,
    href,
    children,
    disable,
    rounder,
    border,
    onClick,
    height,
    width,
    ...pros
}) {
    let Button = 'button';
    if (to) Button = Link;
    if (href) Button = 'a';

    return (
        <div className={styles.wrapper}>
            <Button
                style={{ ...pros, height, width }}
                onClick={onClick}
                className={`${styles.button} ${disable && styles.disable} ${
                    rounder && styles.rounder
                } ${border && styles.border}`}
                to={to}
                href={href}
            >
                {children}
            </Button>
        </div>
    );
}

export default Button;
