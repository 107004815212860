import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import WeekMonthYearPicker from '../../../components/DatePicker/WeekMonthYearPicker';

function Bigchart({ data, options, timeSelect, setTimeSelect }) {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Tooltip,
        Title
    );
    return (
        <div className="row-span-4 overflow-auto flex flex-col">
            <div className="font-semibold pb-10">Phân tích bán hàng</div>
            <div className="flex justify-between items-center">
                <div className="flex items-center">
                    <span className="text-gray-400 mr-2">Tổng số đơn hàng</span>
                    <div className="p-1">
                        <WeekMonthYearPicker
                            timeSelect={timeSelect}
                            setTimeSelect={setTimeSelect}
                        />
                    </div>
                </div>
                <div className="flex gap-10">
                    <span>
                        <span className="text-[10px] pr-3 text-violet-600">
                            <FontAwesomeIcon icon={faCircle} />
                        </span>
                        <span>Mới</span>
                    </span>

                    <span>
                        <span className="text-[10px] pr-3 text-orange-600">
                            <FontAwesomeIcon icon={faCircle} />
                        </span>
                        <span>Huỷ</span>
                    </span>
                </div>
            </div>
            <div className="w-full flex-1 overflow-auto">
                <Line data={data} options={options} />
            </div>
        </div>
    );
}

export default Bigchart;
