import { faCamera, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import usersApi from '../../api/userApi';

function ChangeUserInfo({ user, setUser, setOpen, notifySuccess }) {
    const avtRef = useRef();
    const [avatar, setAvatar] = useState();
    const [newName, setNewName] = useState({
        value: '',
        touched: false,
        valid: true,
    });
    const [hoverAvt, setHoverAvt] = useState(false);

    useEffect(() => {
        if (user) setNewName((prev) => ({ ...prev, value: user.name }));
    }, [user]);
    const handleBrowse = () => {
        avtRef.current.click();
    };

    const handleSetAvatar = (e) => {
        setAvatar(e.target.files[0]);
    };

    const handleSetNewName = (e) => {
        setNewName((prev) => ({
            ...prev,
            value: e.target.value,
            valid: e.target.value !== '',
        }));
    };

    const handleClose = () => {
        setAvatar(null);
        avtRef.current.value = null;
        setOpen(false);
    };

    let urlAvatar;
    if (avatar) urlAvatar = URL.createObjectURL(avatar);

    const handleSubmit = async () => {
        if (avatar || (newName.valid && newName.value !== user.name)) {
            const form = new FormData();
            if (avatar) form.append('photo', avatar);
            if (newName.value) form.append('name', newName.value);
            try {
                const { data } = await usersApi.updateMe(form);
                notifySuccess('Cập nhật thông tin thành công');
                setUser(data.data);
                handleClose();
            } catch (error) {
                console.log(error);
            }
        }
    };

    return (
        <div className="absolute flex gap-10 p-8 z-20 justify-center items-center flex-col top-0 left-0 h-full w-full bg-white rounded-lg">
            <div
                onMouseOver={() => setHoverAvt(true)}
                onMouseLeave={() => setHoverAvt(false)}
                onClick={handleBrowse}
                className={`overflow-auto w-28 aspect-square bg-violet-100 rounded-full flex justify-center items-center ${
                    hoverAvt && 'opacity-60'
                }`}
            >
                <FontAwesomeIcon
                    icon={faCamera}
                    className={`absolute text-2xl ${!hoverAvt && 'hidden'}`}
                />
                {urlAvatar ? (
                    <img
                        className="h-full object-cover"
                        src={urlAvatar}
                        alt="new-avatar"
                    />
                ) : user?.avatar ? (
                    <img
                        src={`${process.env.REACT_APP_API_URL}/img/users/${user.avatar}`}
                        alt="avatar"
                    />
                ) : (
                    <FontAwesomeIcon
                        icon={faUser}
                        className={`text-2xl text-gray-600 ${
                            hoverAvt && 'hidden'
                        }`}
                    />
                )}
                <div>
                    <input
                        onChange={handleSetAvatar}
                        ref={avtRef}
                        hidden
                        type="file"
                        accept="image/*"
                    />
                </div>
            </div>
            <div className="w-full">
                {!newName.valid ? (
                    <span className="text-xs text-red-500">
                        *Tên không thể bỏ trống
                    </span>
                ) : (
                    <span className="text-xs">Tên mới</span>
                )}
                <input
                    autoFocus
                    value={newName.value}
                    onChange={handleSetNewName}
                    type="text"
                    className="bg-slate-200 w-full p-2 rounded-lg outline-none"
                />
            </div>
            <div className="flex gap-2 w-full">
                <button
                    onClick={handleSubmit}
                    className="bg-violet-500  flex-1 text-white p-1"
                >
                    Xác nhận
                </button>
                <button
                    onClick={handleClose}
                    className="border border-solid p-1 flex-1"
                >
                    Huỷ
                </button>
            </div>
        </div>
    );
}

export default ChangeUserInfo;
