import {
    faChevronLeft,
    faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

function YearMonthPicker({ month, setMonth, name, openMonth, setOpenMonth }) {
    const monthList = [
        'Tháng 1',
        'Tháng 2',
        'Tháng 3',
        'Tháng 4',
        'Tháng 5',
        'Tháng 6',
        'Tháng 7',
        'Tháng 8',
        'Tháng 9',
        'Tháng 10',
        'Tháng 11',
        'Tháng 12',
    ];
    const curentMonth = new Date(month).getMonth();
    const [year, setYear] = useState(new Date().getFullYear());

    const handleChevronYear = (e) => {
        const value = e.target.dataset.value;
        if (value === 'left') {
            setYear((prev) => prev - 1);
        }

        if (value === 'right') {
            setYear((prev) => prev + 1);
        }
    };

    const handleSelectMonth = (e) => {
        const newMonth = `${year}-${e.target.dataset.value * 1 + 1}`;
        setMonth(newMonth, name);
        setOpenMonth(false);
    };

    return (
        <div
            className={`absolute z-20 top-0 left-0 w-full h-full bg-white flex flex-col gap-2 p-2 ${
                !openMonth && 'hidden'
            }`}
        >
            <div className="flex items-center justify-center text-slate-800 font-semibold">
                <div className="px-4 flex  w-full items-center justify-between text-slate-800 font-semibold">
                    <FontAwesomeIcon
                        className="p-2 rounded-lg hover:bg-slate-200"
                        onClick={handleChevronYear}
                        data-value="left"
                        icon={faChevronLeft}
                    />

                    <span className="text-lg font-semibold">{year}</span>

                    <FontAwesomeIcon
                        className="p-2 rounded-lg hover:bg-slate-200"
                        onClick={handleChevronYear}
                        data-value="right"
                        icon={faChevronRight}
                    />
                </div>
            </div>
            <ul className="flex-1 grid grid-cols-3">
                {monthList.map((el) => (
                    <li
                        className={`flex items-center justify-center `}
                        key={el}
                    >
                        <span
                            data-value={monthList.indexOf(el)}
                            onClick={handleSelectMonth}
                            className={`p-2 hover:bg-violet-100 hover:text-black rounded-lg ${
                                monthList.indexOf(el) === curentMonth &&
                                'bg-violet-500 text-white'
                            }`}
                        >
                            {el}
                        </span>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default YearMonthPicker;
