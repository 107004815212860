import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faArrowRightToBracket,
    faStore,
} from '@fortawesome/free-solid-svg-icons';
import Button from '../../components/Button/Button';
import Modal from '../../components/Modal/Modal';
import EditShop from './EditShop';
import YesNo from '../../components/Popup/YesNo';
import shopApi from '../../api/shopApi';
import { Link } from 'react-router-dom';
import handleErrorAsync from '../../utils/handleCatchAsync';

function ShopCard({ shop, setShops }) {
    // const shopAcronym = shop.shopname
    //     .toUpperCase()
    //     .split(' ', 2)
    //     .map((el) => el[0]);

    const [openMenu, setOpenMenu] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [action, setAction] = useState('');
    const [deleteApiErrorMsg, setDeleteApiErrorMsg] = useState('');

    let menuClass =
        'absolute flex justify-center items-center flex-col top-0 left-0 w-full h-full bg-white gap-5 p-10 hidden';
    if (openMenu) menuClass = menuClass.replace('hidden', 'block');

    const handleOpenMenu = () => {
        setOpenMenu((prev) => !prev);
    };

    const handleOpenEdit = () => {
        setOpenModal(true);
        setAction('edit');
    };

    const handleOpenDelete = () => {
        setOpenModal(true);
        setAction('delete');
    };

    const handleDelete = async () => {
        try {
            await shopApi.deleteShop(shop._id);
            setShops((prev) => prev.filter((el) => el._id !== shop._id));
            setOpenModal(false);
        } catch (error) {
            handleErrorAsync(error, setDeleteApiErrorMsg);
        }
    };

    return (
        <>
            <div className="w-72 h-72 flex flex-col justify-between overflow-hidden border rounded-lg shadow-md bg-white">
                <div
                    onClick={handleOpenMenu}
                    className="relative flex cursor-default flex-col justify-center items-center flex-1"
                >
                    <span className="h-14 w-14 bg-slate-800 text-white rounded-full flex justify-center items-center overflow-hidden">
                        {shop?.logo ? (
                            <img
                                alt="logo"
                                src={`${process.env.REACT_APP_API_URL}/img/shops/${shop.logo}`}
                            />
                        ) : (
                            <FontAwesomeIcon icon={faStore} />
                        )}
                    </span>
                    <p>{shop?.shopname}</p>
                    <div className={menuClass}>
                        <button
                            className="p-2 bg-slate-300 w-full rounded-lg hover:opacity-75 "
                            onClick={handleOpenEdit}
                        >
                            Sửa
                        </button>
                        <button
                            className="p-2 bg-slate-300 w-full rounded-lg hover:opacity-75 "
                            onClick={handleOpenDelete}
                        >
                            Xoá
                        </button>
                        <Link
                            to={shop._id}
                            className="p-2 bg-slate-300 w-full rounded-lg hover:opacity-75 text-center"
                            onClick={handleOpenDelete}
                        >
                            Truy cập
                        </Link>
                    </div>
                </div>
                <Button to={shop._id}>
                    <FontAwesomeIcon icon={faArrowRightToBracket} />
                    <span className="pl-1">Truy cập</span>
                </Button>
            </div>
            <Modal isOpen={openModal}>
                {action === 'edit' && (
                    <EditShop
                        setShops={setShops}
                        setOpenModal={setOpenModal}
                        shop={shop}
                    />
                )}

                {action === 'delete' && (
                    <YesNo
                        errMsg={deleteApiErrorMsg}
                        action={handleDelete}
                        yes="Xoá"
                        no="Huỷ"
                        setOpenModal={setOpenModal}
                        title={`Bạn đang thực hiện xoá Shop: ${shop.shopname}`}
                    />
                )}
            </Modal>
        </>
    );
}

export default ShopCard;
