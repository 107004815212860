import {
    faCheckCircle,
    faPlus,
    faSpinner,
    faXmarkCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import orderApi from '../../../../api/orderApi';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';

const convertStatus = (number) => {
    switch (number) {
        case 1:
            return (
                <FontAwesomeIcon
                    className="text-green-600"
                    icon={faCheckCircle}
                />
            );
        case 2:
            return <FontAwesomeIcon icon={faXmarkCircle} />;
        default:
            return undefined;
    }
};

function PackingOrder() {
    const oldPackingList = localStorage.getItem('packingList');
    const audioRef = useRef();
    const [packingList, setPackingList] = useState(
        oldPackingList ? JSON.parse(oldPackingList) : []
    );
    const [input, setInput] = useState('');
    const params = useParams();
    const inputRef = useRef();
    const handleAdd = async () => {
        if (!input) return;
        const packingOrder = {
            orderNumber: input,
            createAt: Date.now(),
            status: 0,
            isLoading: false,
        };
        setPackingList((prev) => [
            {
                orderNumber: input,
                createAt: Date.now(),
                status: 0,
                isLoading: true,
            },
            ...prev,
        ]);
        audioRef.current.load();
        audioRef.current.play();
        setInput('');
        inputRef.current?.focus();
        try {
            await orderApi.addOrderPacking(params.shopId, packingOrder);
            setPackingList((prev) =>
                prev.map((el) => {
                    if (el.orderNumber !== packingOrder.orderNumber) return el;
                    return { ...el, status: 1, isLoading: false };
                })
            );
        } catch (error) {
            console.log(error);
            setPackingList((prev) =>
                prev.map((el) => {
                    if (el.orderNumber !== packingOrder.orderNumber) return el;
                    return { ...el, status: 2, isLoading: false };
                })
            );
        }
    };

    const handleEnter = (e) => {
        if (e.key !== 'Enter') return;
        handleAdd();
    };

    const onRemoveList = () => {
        setPackingList([]);
    };

    useEffect(() => {
        localStorage.setItem('packingList', JSON.stringify(packingList));
    }, [packingList]);
    return (
        <div className="h-full w-full flex flex-col">
            <audio ref={audioRef}>
                <source
                    type="audio/mpeg"
                    src="/audio/barcode_sound.mp3"
                    hidden
                />
            </audio>
            <p className="py-2">{`Hôm nay: ${format(
                Date.now(),
                'dd-MM-yyyy'
            )}`}</p>
            <div className="w-full flex gap-4">
                <input
                    onKeyUp={handleEnter}
                    ref={inputRef}
                    value={input}
                    onChange={(e) => setInput(e.currentTarget.value.trim())}
                    type="text"
                    name=""
                    id=""
                    className="bg-slate-200 w-full p-2 rounded-md"
                />
                <button
                    onClick={handleAdd}
                    className="bg-slate-900 text-white h-10 aspect-square rounded-lg active:opacity-60"
                >
                    <FontAwesomeIcon icon={faPlus} />
                </button>
            </div>
            <div className="py-2">
                <button
                    onClick={onRemoveList}
                    className="p-2 bg-green-600 text-white active:opacity-60"
                >
                    Xoá danh sách đơn
                </button>
            </div>
            <div className="h-full p-2">
                <table className="w-full p-2">
                    <thead>
                        <tr className="text-left text-sm grid grid-cols-6 p-2">
                            <th className="col-span-1">No.</th>
                            <th className="col-span-2">Mã vận đơn</th>
                            <th className="col-span-2">Ngày tạo</th>
                            <th className="col-span-1 text-center">
                                Trạng Thái
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {packingList.map((el, index) => (
                            <tr
                                key={index}
                                className="bg-white border-b p-1 hover:bg-slate-200 last:border-none grid grid-cols-6"
                            >
                                <td className="col-span-1">{index + 1}</td>
                                <td className="col-span-2">
                                    {el.orderNumber.toUpperCase()}
                                </td>
                                <td className="col-span-2">
                                    {format(
                                        new Date(el.createAt),
                                        'dd-MM-yyyy hh:mm:ss'
                                    )}
                                </td>
                                <td className="col-span-1 text-center">
                                    {el.isLoading ? (
                                        <FontAwesomeIcon
                                            className="animate-spin"
                                            icon={faSpinner}
                                        />
                                    ) : (
                                        convertStatus(el.status) || '-'
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default PackingOrder;
