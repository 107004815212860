import React from 'react';
import { NavLink } from 'react-router-dom';

function OptionBlock({ option }) {
    let navStyles =
        'w-full rounded-lg flex justify-start items-center gap-x-3 p-3 text-gray-600 hover:text-slate-900 hover:bg-slate-300';
    return (
        <div className="p-x-5 pb-5">
            <span className="text-base font-semibold text-left px-4 py-0 text-gray-500">
                {option.title}
            </span>
            <div className="px-3 flex gap-2 flex-col justify-center items-center text-sm">
                {option.items.map((item) => (
                    <NavLink
                        end
                        key={item.name}
                        to={item.path}
                        className={({ isActive }) =>
                            !isActive
                                ? navStyles
                                : `${navStyles} bg-slate-300 text-slate-900`
                        }
                    >
                        <span className="mr-4 w-2">{item.icon}</span>
                        <span>{item.name}</span>
                    </NavLink>
                ))}
            </div>
        </div>
    );
}

export default OptionBlock;
