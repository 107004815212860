import React from 'react';
import OptionBlock from './OptionBlock';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBox,
    faBoxesPacking,
    faChartBar,
    faChartPie,
    faGears,
    faHome,
    faNoteSticky,
    faRotateLeft,
    faTruckFast,
    faUser,
    faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';

function SideBar({ userInfo }) {
    const options = [
        {
            title: 'Báo cáo',
            items: [
                {
                    path: 'overview',
                    icon: <FontAwesomeIcon icon={faChartPie} />,
                    name: 'Tổng quan',
                },
            ],
        },
        {
            title: 'Vận đơn',
            items: [
                {
                    name: 'Chờ vận chuyển',
                    path: 'order-packed',
                    icon: <FontAwesomeIcon icon={faBoxesPacking} />,
                },

                {
                    name: 'Đã huỷ',
                    path: 'order-cancelled',
                    icon: <FontAwesomeIcon icon={faXmark} />,
                },
                {
                    name: 'Đã hoàn',
                    path: 'order-returned',
                    icon: <FontAwesomeIcon icon={faRotateLeft} />,
                },
            ],
        },
        {
            title: 'Quản lý đơn',
            items: [
                {
                    name: 'Quản lý đơn',
                    path: 'order-manager',
                    icon: <FontAwesomeIcon icon={faChartBar} />,
                },
                // {
                //     name: 'Đã đóng gói',
                //     path: 'order-packed-report',
                //     icon: <FontAwesomeIcon icon={faBox} />,
                // },
                {
                    name: 'Đơn đã hoàn',
                    path: 'returned-manager',
                    icon: (
                        <FontAwesomeIcon
                            className="-scale-x-100"
                            icon={faTruckFast}
                        />
                    ),
                },
            ],
        },
        {
            title: 'ĐÓNG GÓI',
            items: [
                {
                    name: 'Đã đóng gói',
                    path: 'packing-order',
                    icon: <FontAwesomeIcon icon={faBox} />,
                },
                {
                    name: 'Danh sách đơn',
                    path: 'packing-manager',
                    icon: (
                        <FontAwesomeIcon
                            className="-scale-x-100"
                            icon={faNoteSticky}
                        />
                    ),
                },
            ],
        },
    ];

    const settingOptions = {
        title: 'Quản lý shop',
        items: [
            {
                path: 'setting',
                icon: <FontAwesomeIcon icon={faGears} />,
                name: 'Cài đặt shop',
            },
            {
                path: '/shop',
                icon: <FontAwesomeIcon icon={faHome} />,
                name: 'Quản lý shop',
            },
        ],
    };

    return (
        <div className="shadow-2xl relative pt-5 h-full w-full flex flex-col justify-between overflow-auto">
            <div>
                {options.map((option) => (
                    <OptionBlock key={option.title} option={option} />
                ))}
            </div>
            <div>
                <OptionBlock option={settingOptions} />

                <div className="p-3 relative  border-t">
                    <NavLink
                        to="user-setting"
                        className="px-3 py-2  flex items-center gap-x-3 text-gray-600 rounded-lg text-sm hover:bg-slate-300"
                    >
                        <span className="w-7 h-7 flex rounded-full justify-center items-center bg-violet-100 overflow-auto">
                            {userInfo?.avatar ? (
                                <img
                                    src={`${process.env.REACT_APP_API_URL}/img/users/${userInfo.avatar}`}
                                    alt="avatar"
                                />
                            ) : (
                                <FontAwesomeIcon icon={faUser} />
                            )}
                        </span>
                        <span className="whitespace-nowrap overflow-auto text-ellipsis">
                            {userInfo?.name}
                        </span>
                    </NavLink>
                </div>
            </div>
        </div>
    );
}

export default SideBar;
