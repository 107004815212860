import React from 'react';

import {
    faChevronLeft,
    faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Pagination({ totalItem, onChangeLimit, limit, page, onChevron }) {
    const totalPages = Math.ceil(totalItem / limit);
    return (
        <div className="flex justify-end items-center gap-2">
            <span
                className={`py-1 px-2 rounded-lg  ${
                    page - 1 === 0
                        ? 'text-gray-300 cursor-not-allowed'
                        : 'hover:bg-gray-300'
                }`}
                onClick={onChevron}
                name="left"
            >
                <FontAwesomeIcon
                    className="pointer-events-none"
                    icon={faChevronLeft}
                />
            </span>
            <div>{`${page}-${totalPages || 1} / ${totalItem || ''} đơn`}</div>
            <span
                className={`py-1 px-2 rounded-lg ${
                    page + 1 > totalPages
                        ? 'text-gray-300 cursor-not-allowed'
                        : ' hover:bg-gray-300'
                }`}
                onClick={onChevron}
                name="right"
            >
                <FontAwesomeIcon
                    className="pointer-events-none"
                    icon={faChevronRight}
                />
            </span>
            <select
                onChange={onChangeLimit}
                className="border rounded-full ml-2"
                defaultValue={limit}
            >
                <option value="10">10/trang</option>
                <option value="50">50/trang</option>
                <option value="100">100/trang</option>
                <option value="200">200/trang</option>
                <option value="1000">1000/trang</option>
            </select>
        </div>
    );
}

export default Pagination;
