import React from 'react';
import { useRouteError } from 'react-router-dom';

function Error5xx() {
    const error = useRouteError();
    console.log(error);
    return <div>Error5xx</div>;
}

export default Error5xx;
