import React, { useState } from 'react';
import { Link, redirect, useNavigate } from 'react-router-dom';
import Validator from '../../utils/Validator';
import authApi from '../../api/authApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import Button from '../../components/Button/Button';
import FormInput from '../../components/FormInput/FormInput';

function Login() {
    const navigate = useNavigate();
    const formInput = [
        {
            id: '2',
            name: 'email',
            type: 'text',
            placeholder: 'Email',
            label: 'Email',
            errorMessage: 'Vui lòng nhập email',
        },
        {
            id: '3',
            name: 'password',
            type: 'password',
            placeholder: 'Mật khẩu',
            label: 'Mật khẩu',
            errorMessage: 'Mật khẩu phải lớn hơn 8 ký tự',
        },
    ];

    const [input, setInput] = useState({
        email: '',
        password: '',
    });
    const [inputValid, setInputValid] = useState({
        email: true,
        password: true,
    });
    const [submitting, setSubmitting] = useState(false);
    const [errMsg, setErrMsg] = useState();

    //VALIDATE FORM OPTION
    const validate = {
        email: () => {
            return new Validator(input.email).isEmail().isRequired().valid;
        },

        password: () => {
            return new Validator(input.password).isRequired().valid;
        },
    };

    const validAll = Object.keys(input).every((key) => {
        return validate[key]();
    });

    //HANDLE INPUT
    const handleBlur = (e) => {
        setInputValid((prev) => {
            return { ...prev, [e.target.name]: validate[e.target.name]() };
        });
    };
    const handleOnchange = (e) => {
        setInputValid((prev) => {
            return { ...prev, [e.target.name]: true };
        });
        setInput((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    //HANDLE SUBMIT
    const handleSubmit = async () => {
        if (!validAll) {
            return;
        }
        setSubmitting(true);
        try {
            const { data } = await authApi.login(input);
            //save token to Local Storage
            localStorage.setItem('access_token', data.token);
            localStorage.setItem('user', JSON.stringify(data.user));
            //redirect to /shop
            navigate('/shop');
            setSubmitting(false);
        } catch ({ response }) {
            setSubmitting(false);
            if (`${response?.status}`.startsWith('4')) {
                return setErrMsg(
                    'Tài khoản hoặc mật khẩu không đúng. Vui lòng kiểm tra và thử lại'
                );
            }
            if (`${response.status}`.startsWith('5')) {
                return setErrMsg('Có lỗi! vui lòng thử lại sau');
            }
        }
    };

    const wrapperEnter = (e) => {
        if (e.key === 'Enter') handleSubmit();
    };

    return (
        <div
            onKeyDown={wrapperEnter}
            className="w-full flex justify-center items-center h-screen bg-zinc-50"
        >
            <div className="w-full px-8 py-10 sm:w-96 sm:shadow sm:rounded-lg sm:bg-white ">
                <p className="block text-2xl mb-3 font-semibold">Đăng nhập</p>
                {errMsg && (
                    <div className="flex items-center border p-2 mb-2 border-red-500 text-red-500">
                        <FontAwesomeIcon icon={faXmarkCircle} />
                        <p className="ml-2">{errMsg}</p>
                    </div>
                )}
                <FormInput
                    onChange={handleOnchange}
                    onBlur={handleBlur}
                    formInput={formInput}
                    inputValid={inputValid}
                />
                <div className="flex justify-end text-sm py-1 text-violet-900">
                    <Link to="/forgotpassword">Quên mật khẩu</Link>
                </div>
                <Button
                    marginBottom={20}
                    height={50}
                    onClick={handleSubmit}
                    disable={!validAll || submitting}
                    rounder
                >
                    Đăng nhập
                </Button>
                <Button
                    marginBottom={20}
                    height={50}
                    onClick={handleSubmit}
                    rounder
                    border
                >
                    Đăng nhập với Google
                </Button>
                <div className="flex justify-center items-center">
                    <span className="mr-1">Bạn chưa có tài khoản?</span>
                    <Link className="text-violet-900" to="/signup">
                        Đăng ký
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Login;

Login.loader = () => {
    if (localStorage.getItem('access_token')) return redirect('/shop');
    return null;
};
