import {
    faChevronLeft,
    faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

function YearPicker({
    openYear,
    onMouseOver,
    setOpenYear,
    year,
    setYear,
    name,
}) {
    const [startYear, setStartYear] = useState(year - 8);
    const yearList = [];
    for (let i = 1; i < 13; i++) {
        yearList.push(startYear + i);
    }

    const handleSelectYear = (e) => {
        setYear(e, name);
        if (setOpenYear) setOpenYear(false);
    };

    const handleChevronYear = (e) => {
        const value = e.target.dataset.value;
        if (value === 'left') {
            setStartYear((prev) => prev - 12);
        }

        if (value === 'right') {
            setStartYear((prev) => prev + 12);
        }
    };

    return (
        <div
            className={`absolute z-20 top-0 left-0 w-full h-full bg-white flex flex-col gap-2 p-2 ${
                !openYear && 'hidden'
            }`}
        >
            <div className="px-6 flex items-center justify-between text-slate-800 font-semibold">
                <span
                    onClick={handleChevronYear}
                    className="p-2 rounded-lg hover:bg-slate-200"
                    data-value="left"
                >
                    <FontAwesomeIcon
                        className="pointer-events-none"
                        icon={faChevronLeft}
                    />
                </span>
                <span className="text-lg font-semibold">{`${yearList[0]} - ${
                    yearList[yearList.length - 1]
                }`}</span>
                <span
                    onClick={handleChevronYear}
                    className="p-2 rounded-lg hover:bg-slate-200"
                    data-value="right"
                >
                    <FontAwesomeIcon
                        className="pointer-events-none"
                        icon={faChevronRight}
                    />
                </span>
            </div>
            <ul className="flex-1 grid grid-cols-3">
                {yearList.map((el) => (
                    <li className="flex items-center justify-center" key={el}>
                        <span
                            data-value={el}
                            onClick={handleSelectYear}
                            className={`p-2 hover:bg-violet-100 hover:text-black rounded-lg ${
                                el === year && 'bg-violet-500 text-white'
                            }`}
                        >
                            {el}
                        </span>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default YearPicker;
