import axiosClient from './axiosClient';

const authApi = {
    signup: async (data) => {
        const baseURL = 'users/signup';
        return await axiosClient.post(baseURL, data);
    },
    login: async (data) => {
        const baseURL = 'users/login';
        return await axiosClient.post(baseURL, data);
    },
    forgotPassword: (email) => {
        const baseURL = 'users/forgot-password';
        return axiosClient.post(baseURL, email);
    },
    verifyResetPasswordOTP: (OTP) => {
        const baseURL = 'users/reset-password/checkOTP';
        return axiosClient.post(baseURL, OTP);
    },
    resetPassword: (data) => {
        const baseURL = 'users/reset-password';
        return axiosClient.post(baseURL, data);
    },
};

export default authApi;
