import React from 'react';

function StepBar({ step }) {
    const stepList = [1, 2, 3, 4];
    return (
        <div className="grid grid-cols-4 gap-2">
            {stepList.map((el) => (
                <span
                    key={el}
                    className={`rounded-xl h-2 ${
                        el === step ? 'bg-violet-500' : 'bg-violet-200'
                    }`}
                ></span>
            ))}
        </div>
    );
}

export default StepBar;
