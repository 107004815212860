export default class Validator {
    constructor(input) {
        this.input = input;
        this.valid = true;
    }

    isRequired() {
        if (this.valid && this.input.trim() === '') {
            this.valid = false;
        }

        return this;
    }
    isEmail() {
        const regex =
            /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (this.valid && !regex.test(this.input)) {
            this.valid = false;
        }
        return this;
    }
    isMatch(value) {
        if (this.valid && value !== this.input) {
            this.valid = false;
        }
        return this;
    }

    isPassword() {
        if (this.valid && this.input.length < 8) {
            this.valid = false;
        }
        return this;
    }

    minLength(value) {
        if (this.valid && this.input.length < value) {
            this.valid = false;
        }
        return this;
    }
}
