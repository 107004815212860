import axiosClient from './axiosClient';

const orderApi = {
    findOrder: async (id, orderNumber) => {
        const baseURL = `shop/${id}/orders/${orderNumber}`;
        return await axiosClient.get(baseURL);
    },
    newOrderPacked: async (id, packedList) => {
        const baseURL = `shop/${id}/orders/packed`;
        return await axiosClient.post(baseURL, { packedList });
    },
    importOrderPacked: async (id, file) => {
        const baseURL = `shop/${id}/orders/packed/import`;
        return await axiosClient.post(baseURL, file, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },

    importOrderReturned: async (id, file) => {
        const baseURL = `shop/${id}/orders/returned/import`;
        return await axiosClient.patch(baseURL, file, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },

    updateOrderReturned: async (id, returnedList) => {
        const baseURL = `shop/${id}/orders/returned`;
        return await axiosClient.patch(baseURL, returnedList);
    },

    updateOrderCancelledByFormData: async (id, cancelledData) => {
        const baseURL = `shop/${id}/orders/cancelled`;
        return await axiosClient.patch(baseURL, cancelledData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },

    updateOrderCancelledByTextArea: async (id, cancelledData) => {
        const baseURL = `shop/${id}/orders/cancelled`;
        return await axiosClient.patch(baseURL, cancelledData);
    },
    getAllOrder: async (id, filter) => {
        const baseURL = `shop/${id}/orders?${filter}`;
        return await axiosClient.get(baseURL);
    },
    updateNote: async (shopId, orderId, note) => {
        const baseURL = `shop/${shopId}/orders/${orderId}/notes`;
        return await axiosClient.patch(baseURL, note);
    },
    getStatsOrders: (id, filter) => {
        const baseURL = `shop/${id}/statsOrders?${filter}`;
        return axiosClient.get(baseURL);
    },
    orderTodayStats: (shopId) => {
        const baseURL = `shop/${shopId}/ordersToday`;
        return axiosClient.get(baseURL);
    },
    ordersThisMonthStats: (shopId) => {
        const baseURL = `shop/${shopId}/ordersOfThisMonth`;
        return axiosClient.get(baseURL);
    },
    returningStats: (shopId) => {
        const baseURL = `shop/${shopId}/returningStats`;
        return axiosClient.get(baseURL);
    },
    top10returning: (shopId) => {
        const baseURL = `shop/${shopId}/top10returning`;
        return axiosClient.get(baseURL);
    },
    addOrderPacking(shopId, data) {
        const baseURL = `shop/${shopId}/orders/packing`;
        return axiosClient.post(baseURL, {
            packingOrder: {
                orderNumber: data.orderNumber,
                createAt: data.createAt,
            },
        });
    },
    getPackingOrders: (shopId, filter) => {
        const baseURL = `shop/${shopId}/packinglist?${filter}`;
        return axiosClient.get(baseURL);
    },
};

export default orderApi;

//{{URL}}api/v1/shop/646de323b3af807f0aac1454/orders/returned
