import {
    faSearch,
    faSpinner,
    faXmarkCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';

function Search({
    value,
    onChange,
    sugList,
    isLoading,
    placeholder,
    onSelect,
}) {
    const [isFocused, setIsFocused] = useState(false);
    const [input, setInput] = useState('');

    useEffect(() => {
        if (!value) setInput('');
    }, [value]);
    let typingTimer;
    const delayActionTime = 500;

    const handleTypingDone = (e) => {
        clearTimeout(typingTimer);
        if (e.target.value) {
            typingTimer = setTimeout(() => {
                onChange(e.target.value);
            }, delayActionTime);
        }
    };

    const handleClearInput = () => {
        setInput('');
        onChange('');
    };

    return (
        <div className="h-full relative">
            <input
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder={placeholder}
                onFocus={() => setIsFocused(true)}
                onKeyUp={handleTypingDone}
                className="w-full h-full p-2 pl-9 bg-slate-200 rounded-3xl  outline-none"
                type="text"
            />
            <span className="absolute flex items-center justify-center text-gray-500 top-[50%] left-[10px] translate-y-[-50%]">
                <FontAwesomeIcon icon={faSearch} />
            </span>
            {input && (
                <span className="absolute text-gray-500 top-[50%] right-[10px] translate-y-[-50%] hover:text-slate-800">
                    {isLoading ? (
                        <FontAwesomeIcon
                            className="animate-spin"
                            icon={faSpinner}
                        />
                    ) : (
                        <FontAwesomeIcon
                            onClick={handleClearInput}
                            icon={faXmarkCircle}
                        />
                    )}
                </span>
            )}
            {sugList?.length > 0 && value && (
                <ul
                    className={`${
                        !isFocused && 'hidden'
                    } absolute top-[100%] overflow-hidden w-full mt-2 bg-white shadow-md rounded-lg z-10`}
                >
                    {sugList.map((el, index) => (
                        <li
                            className="p-3 hover:bg-gray-200"
                            key={index}
                            onClick={onSelect}
                        >
                            {el}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}

export default Search;
