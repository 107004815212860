import React, { useEffect, useState } from 'react';
import { NavLink, Outlet, useParams } from 'react-router-dom';
import shopApi from '../../../api/shopApi';

function ShopSetting() {
    const [shopInfo, setShopInfo] = useState();
    const params = useParams();
    useEffect(() => {
        const fetchShop = async () => {
            try {
                const { data } = await shopApi.getShopById(params.shopId);
                setShopInfo(data.shop);
            } catch (error) {
                console.log(error);
            }
        };
        fetchShop();
    }, [params]);
    return (
        <div className="h-full w-full flex flex-col gap-4">
            <div>
                <span className="text-2xl font-semibold mb-5 block">
                    Thiết lập shop
                </span>
                <div className="flex gap-5">
                    <div className="text-gray-500 text-sm font-semibold hover:text-slate-900">
                        <NavLink
                            end
                            to="shopinfo"
                            className={({ isActive }) =>
                                isActive ? 'text-slate-900 font-semibold' : null
                            }
                        >
                            Thông tin
                        </NavLink>
                    </div>
                    <div className="text-gray-500 text-sm font-semibold hover:text-slate-900">
                        <NavLink
                            end
                            to="staff"
                            className={({ isActive }) =>
                                isActive ? 'text-slate-900 font-semibold' : null
                            }
                        >
                            Nhân viên
                        </NavLink>
                    </div>
                </div>
            </div>

            <Outlet context={shopInfo} />
        </div>
    );
}

export default ShopSetting;
