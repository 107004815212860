import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
    faArrowTrendDown,
    faArrowTrendUp,
    faChartSimple,
} from '@fortawesome/free-solid-svg-icons';
import convertRatio from '../../../utils/convertRatioToText';

function MonthTotal({ orderThisMonth }) {
    let thisMonth = 0;
    let lastMonth = 0;
    let thisMonthCancelled = 0;
    let lastMonthCancelled = 0;
    if (orderThisMonth && orderThisMonth.length > 0) {
        thisMonth = orderThisMonth[0].thisMonth;
        lastMonth = orderThisMonth[0].lastMonth;
        thisMonthCancelled = orderThisMonth[0].thisMonthCancelled;
        lastMonthCancelled = orderThisMonth[0].lastMonthCancelled;
    }

    const ratioNew = (100 * (thisMonth - lastMonth)) / lastMonth;
    const ratioCancelled =
        (100 * (thisMonthCancelled - lastMonthCancelled)) / lastMonthCancelled;

    const checkStatusRatio = (ratio) => {
        if (ratio === Infinity || ratio > 0) return 'up';
        if (ratio === -Infinity || ratio < 0) return 'down';
        return undefined;
    };

    return (
        <div className="px-5 pt-5 flex flex-col gap-5">
            <span className="font-semibold">Thống kê tháng</span>
            <div className=" relative h-24 flex justify-center items-center border rounded-lg">
                {/* Packed Stats */}
                <div className="flex-1 flex justify-center items-center flex-col p-2">
                    <span className="text-violet-500 text-xl">
                        <FontAwesomeIcon icon={faChartSimple} />
                    </span>
                    <div>
                        <p className="font-semibold text-center">
                            {thisMonth || 0}
                        </p>
                        <div className="flex gap-2">
                            <span className="text-gray-600 text-sm">Mới</span>
                            <span
                                className={`flex  gap-2 text-xs items-center justify-center ${
                                    checkStatusRatio(ratioNew) === 'up' &&
                                    'bg-green-100 text-green-600'
                                }  ${
                                    checkStatusRatio(ratioNew) === 'down' &&
                                    'bg-red-100 text-red-600'
                                } px-1 rounded-lg ${
                                    !checkStatusRatio(ratioNew) &&
                                    'bg-orange-100'
                                }`}
                            >
                                {(ratioNew || ratioNew === Infinity) > 0 && (
                                    <FontAwesomeIcon icon={faArrowTrendUp} />
                                )}
                                {ratioNew < 0 && (
                                    <FontAwesomeIcon icon={faArrowTrendDown} />
                                )}
                                <span>{convertRatio(ratioNew)}</span>
                            </span>
                        </div>
                    </div>
                </div>

                {/*Cancelled Stats  */}
                <div className="flex-1 flex justify-center items-center flex-col p-2">
                    <span className="text-orange-500 text-xl">
                        <FontAwesomeIcon icon={faChartSimple} />
                    </span>
                    <div>
                        <p className="font-semibold text-center">
                            {thisMonthCancelled || 0}
                        </p>
                        <div className="flex gap-2">
                            <span className="text-gray-600 text-sm">Huỷ</span>
                            <span
                                className={`flex  gap-2 text-xs items-center justify-center ${
                                    checkStatusRatio(ratioCancelled) === 'up' &&
                                    'bg-green-100 text-green-600'
                                }  ${
                                    checkStatusRatio(ratioCancelled) ===
                                        'down' && 'bg-red-100 text-red-600'
                                } px-1 rounded-lg ${
                                    !checkStatusRatio(ratioCancelled) &&
                                    'bg-orange-100'
                                }`}
                            >
                                {(ratioCancelled > 0 ||
                                    ratioCancelled === Infinity) && (
                                    <FontAwesomeIcon icon={faArrowTrendUp} />
                                )}
                                {ratioCancelled < 0 && (
                                    <FontAwesomeIcon icon={faArrowTrendDown} />
                                )}
                                <span>{convertRatio(ratioCancelled)}</span>
                            </span>
                        </div>
                    </div>
                </div>
                <span className="absolute top-1/2 left-1/2 border h-3/4 -translate-x-1/2 -translate-y-1/2"></span>
            </div>
        </div>
    );
}

export default MonthTotal;
