import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faArrowRight,
    faCheck,
    faEraser,
    faSpinner,
    faXmark,
} from '@fortawesome/free-solid-svg-icons';

function ShopOwner({
    shopInfo,
    openEdit,
    handleCloseEdit,
    handleOpenEdit,
    newOwner,
    handleChangeOwner,
    handleTypingDone,
    isLoading,
}) {
    return (
        <div className=" border rounded-xl bg-white p-5 flex flex-col gap-3">
            <div className="flex justify-between items-center w-full">
                <span className="text-sm font-semibold text-slate-600">
                    Thông tin chủ sở hữu
                </span>
                <div>
                    <div className="flex">
                        <span
                            data-value="owner"
                            onClick={handleOpenEdit}
                            className={` text-sm border rounded-2xl py-1 px-2 cursor-pointer hover:border-slate-900 ${
                                openEdit.owner && 'hidden'
                            }`}
                        >
                            Sửa{' '}
                            <FontAwesomeIcon
                                className="pointer-events-none"
                                icon={faEraser}
                            />
                        </span>
                    </div>
                    <div className="flex gap-2">
                        <span
                            onClick={handleChangeOwner}
                            className={`w-14 text-center bg-violet-500 text-sm text-white border rounded-2xl py-1 px-2  hover:opacity-60 ${
                                !openEdit.owner && 'hidden'
                            } ${
                                !newOwner
                                    ? 'opacity-70 cursor-not-allowed'
                                    : 'cursor-pointer'
                            }`}
                        >
                            <FontAwesomeIcon
                                className="pointer-events-none"
                                icon={faCheck}
                            />
                        </span>
                        <span
                            onClick={handleCloseEdit}
                            className={`w-14 text-center text-sm border rounded-2xl py-1 px-2 cursor-pointer hover:border-slate-900 ${
                                !openEdit.owner && 'hidden'
                            }`}
                        >
                            <FontAwesomeIcon
                                className="pointer-events-none"
                                icon={faXmark}
                            />
                        </span>
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-2">
                <div className="flex flex-col gap-1">
                    <span className="text-sm text-slate-600">Họ và tên</span>
                    <div className="flex gap-2 items-center">
                        <span>{shopInfo.owner?.name || 'unknown'}</span>
                        {openEdit.owner && (
                            <>
                                {(isLoading ||
                                    newOwner ||
                                    newOwner === undefined) && (
                                    <span>
                                        <FontAwesomeIcon icon={faArrowRight} />
                                    </span>
                                )}
                                {isLoading ? (
                                    <FontAwesomeIcon
                                        className="animate-spin"
                                        icon={faSpinner}
                                    />
                                ) : newOwner || newOwner === undefined ? (
                                    newOwner ? (
                                        <span className="bg-violet-100 px-2 rounded-xl">
                                            {newOwner.name}
                                        </span>
                                    ) : (
                                        <span className="text-red-500">
                                            *người dùng không tồn tại
                                        </span>
                                    )
                                ) : null}
                            </>
                        )}
                    </div>
                </div>
                <div className="flex flex-col gap-1">
                    <span className="text-sm text-slate-600">Email</span>
                    <div className="flex">
                        {openEdit.owner ? (
                            <input
                                onKeyUp={handleTypingDone}
                                autoFocus
                                className="bg-slate-200 p-2 rounded-lg w-2/3 outline-none"
                                placeholder="Nhập email"
                            />
                        ) : (
                            <span>{shopInfo.owner?.email || 'unknown'}</span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ShopOwner;
