import React from 'react';

function MonthPicker({ month, setMonth, name, openMonth, setOpenMonth }) {
    const monthList = [
        'Tháng 1',
        'Tháng 2',
        'Tháng 3',
        'Tháng 4',
        'Tháng 5',
        'Tháng 6',
        'Tháng 7',
        'Tháng 8',
        'Tháng 9',
        'Tháng 10',
        'Tháng 11',
        'Tháng 12',
    ];

    const handleSelectMonth = (e) => {
        setMonth(e, name);
        setOpenMonth(false);
    };

    return (
        <div
            className={`absolute z-20 top-0 left-0 w-full h-full bg-white flex flex-col gap-2 p-2 ${
                !openMonth && 'hidden'
            }`}
        >
            <div className="flex items-center justify-center text-slate-800 font-semibold">
                <span className="text-lg font-semibold">Chọn Tháng</span>
            </div>
            <ul className="flex-1 grid grid-cols-3">
                {monthList.map((el) => (
                    <li className="flex items-center justify-center" key={el}>
                        <span
                            data-value={monthList.indexOf(el)}
                            onClick={handleSelectMonth}
                            className="p-2 hover:bg-violet-500 hover:text-white rounded-lg"
                        >
                            {el}
                        </span>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default MonthPicker;
