import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './pages/home/Home';
import Shop from './pages/shop/Shop';
import Login from './pages/auth/Login';
import Signup from './pages/auth/Signup';
import ForgotPassword from './pages/auth/ForgotPassword/ForgotPassword.js';
import './index.css';
import DashBoardMain from './pages/DashBoard/DashBoardMain';
import Overview from './pages/DashBoard/Overview/Overview';
import OrderPacked from './pages/DashBoard/OrderPacked/OrderPacked';
import OrderReturned from './pages/DashBoard/OrderReturned/OrderReturned';
import OrderCancelled from './pages/DashBoard/OrderCancelled/OrderCancelled';
import OrderManager from './pages/DashBoard/OrderManager/OrderManager';
import Error5xx from './pages/error/Error5xx';
import ShopSetting from './pages/DashBoard/ShopSetting/ShopSetting';
import ShopInfo from './pages/DashBoard/ShopSetting/ShopInfo';
import StaffSetting from './pages/DashBoard/ShopSetting/StaffSetting';
import ReturnedManager from './pages/DashBoard/ReturnedManager/ReturnedManager';
import UserSetting from './pages/user/UserSetting';
import PackingOrder from './pages/DashBoard/Packing/PackingOrder';
import PackingManager from './pages/DashBoard/Packing/PackingManager';

function App() {
    const router = createBrowserRouter([
        {
            path: '/',
            element: <Home />,
            loader: Login.loader,
        },
        {
            path: '/shop',
            element: <Shop />,
            errorElement: <Error5xx />,
            loader: Shop.loader,
        },

        {
            path: '/shop/:shopId',
            element: <DashBoardMain />,
            errorElement: <Error5xx />,
            loader: DashBoardMain.loader,
            children: [
                {
                    index: true,
                    element: <Overview />,
                },
                {
                    path: 'overview',
                    element: <Overview />,
                },
                {
                    path: 'order-packed',
                    element: <OrderPacked />,
                },
                {
                    path: 'order-returned',
                    element: <OrderReturned />,
                },
                {
                    path: 'order-cancelled',
                    element: <OrderCancelled />,
                },
                {
                    path: 'packing-order',
                    element: <PackingOrder />,
                },
                {
                    path: 'packing-manager',
                    element: <PackingManager />,
                },
                {
                    path: 'order-manager',
                    element: <OrderManager />,
                },
                {
                    path: 'returned-manager',
                    element: <ReturnedManager />,
                },
                {
                    path: 'user-setting',
                    element: <UserSetting />,
                },
                {
                    path: 'setting',
                    element: <ShopSetting />,
                    children: [
                        {
                            index: true,
                            element: <ShopInfo />,
                        },
                        {
                            path: 'shopinfo',
                            element: <ShopInfo />,
                        },
                        {
                            path: 'staff',
                            element: <StaffSetting />,
                        },
                    ],
                },
            ],
        },

        { path: '/login', loader: Login.loader, element: <Login /> },
        { path: '/signup', element: <Signup /> },
        { path: '/forgotpassword', element: <ForgotPassword /> },
    ]);
    return <RouterProvider router={router} />;
}

export default App;
