import React from 'react';
import { createPortal } from 'react-dom';
import styles from './Modal.module.css';

function Modal({ children, isOpen }) {
    return createPortal(
        <div
            onClick={(e) => e.stopPropagation()}
            className={`${styles.modal} ${isOpen && styles.isOpen}`}
        >
            {children}
        </div>,
        document.getElementById('portal')
    );
}

export default Modal;
