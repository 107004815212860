import React, { useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import {
    faCloudArrowUp,
    faFileCsv,
    faFileExcel,
    faTrashCan,
} from '@fortawesome/free-solid-svg-icons';

function DragDropFile({
    setFile,
    file,
    deleteFile,
    onSelect,
    validate,
    selected,
}) {
    const selectOptions = [
        {
            value: 'shopee',
            label: 'Shopee',
        },
        {
            value: 'lazada',
            label: 'Lazada',
        },
        {
            value: 'tiktok',
            label: 'Tiktok',
        },
    ];

    const inputRef = useRef();

    const handleBrowse = () => {
        inputRef.current.click();
    };
    const [onDragOver, setOnDragOver] = useState(false);

    const handleDragOver = (e) => {
        e.preventDefault();
        setOnDragOver(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setOnDragOver(false);
    };

    const handleOnDrop = (e) => {
        e.preventDefault();
        setOnDragOver(false);
        setFile(e.dataTransfer.files[0]);
    };

    const handleDeleteFile = () => {
        deleteFile();
        inputRef.current.value = null;
    };

    let icon;
    switch (file?.type) {
        case 'text/csv':
            icon = <FontAwesomeIcon icon={faFileCsv} />;
            break;

        default:
            icon = <FontAwesomeIcon icon={faFileExcel} />;
            break;
    }
    let dragBoxStyles =
        'hover:border-slate-600 w-full bg-white drop-shadow-lg h-48 flex justify-center items-center bg-white shadow-md rounded-lg border-dashed border-4';

    if (file || onDragOver) dragBoxStyles += ' border-slate-600';

    return (
        <div className="w-full flex flex-col gap-10 ">
            <div
                onDrop={handleOnDrop}
                onDragLeave={handleDragLeave}
                onDragOver={handleDragOver}
                onClick={handleBrowse}
                className={dragBoxStyles}
            >
                <div className="flex flex-col  justify-center items-center cursor-default bg-white dr">
                    <span className="text-5xl text-slate-700 w-10">
                        <FontAwesomeIcon icon={faCloudArrowUp} />
                    </span>
                    <p>
                        Kéo Thả hoặc
                        <span className="font-bold"> Chọn File </span> để upload
                    </p>
                </div>
                <input
                    hidden
                    onChange={(e) => setFile(e.target.files[0])}
                    ref={inputRef}
                    type="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
            </div>
            {file && (
                <div className="flex justify-between items-center gap-5 p-5 bg-white shadow-md rounded-lg border border-slate-800">
                    <span className="text-5xl text-slate-700">{icon}</span>
                    <div className="flex flex-col flex-grow overflow-auto">
                        <span>{file.name}</span>
                        <div>
                            <span className="p-[2px] inline text-sm bg-violet-200 rounded-lg">{`${file.size} bytes`}</span>
                        </div>
                    </div>

                    <div className={`${!validate && 'animate-shake'}`}>
                        <Select
                            value={selected}
                            styles={{
                                control: (baseStyle, state) => ({
                                    ...baseStyle,
                                    height: 40,
                                    width: 140,
                                    borderRadius: 8,
                                    borderColor: validate ? 'gray' : 'red',
                                    boxShadow: 'black',
                                    ':hover': {
                                        borderColor: 'black',
                                    },
                                }),
                                option: (baseStyle, state) => ({
                                    ...baseStyle,
                                    background: state.isSelected
                                        ? '#1f2937'
                                        : 'unset',
                                    ':hover': {
                                        background: '#e2e8f0',
                                    },
                                }),
                            }}
                            onChange={onSelect}
                            options={selectOptions}
                            placeholder="Kênh bán"
                        />
                    </div>

                    <span onClick={handleDeleteFile}>
                        <FontAwesomeIcon icon={faTrashCan} />
                    </span>
                </div>
            )}
        </div>
    );
}

export default DragDropFile;
