import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

function Step4() {
    const navigate = useNavigate();
    const [countdown, setCountdown] = useState(6);

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown((prev) => prev - 1);
        }, 1000);

        if (countdown === 0) {
            navigate('/login');
        }

        return () => {
            clearInterval(timer);
        };
    });

    return (
        <div className="flex-1 flex flex-col gap-10 justify-center">
            <div className="flex flex-col justify-center items-center gap-2">
                <span className="my-0 mx-auto">
                    <FontAwesomeIcon
                        className="text-2xl p-4 border rounded-lg text-green-600"
                        icon={faCheck}
                    />
                </span>
                <p className="text-xl font-semibold">
                    Đặt lại mật khẩu thành công
                </p>
            </div>
            <div className="flex justify-center">
                <Link
                    to="/login"
                    className="bg-violet-500 p-3 rounded-lg text-center text-white"
                >
                    Đăng nhập
                </Link>
            </div>
        </div>
    );
}

export default Step4;
