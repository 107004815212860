import React, { useState } from 'react';
import authApi from '../../../api/authApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKeyboard } from '@fortawesome/free-regular-svg-icons';
import handleErrorAsync from '../../../utils/handleCatchAsync';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import Validator from '../../../utils/Validator';
import ToLogin from './ToLogin';

function Step3({ otp, setStep }) {
    const [errorMsg, setErrorMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [input, setInput] = useState({
        password: {
            touched: false,
            value: '',
            invalid: undefined,
        },
        passwordConfirm: {
            touched: false,
            value: '',
            invalid: undefined,
        },
    });

    const handleChangeInput = (e) => {
        const value = e.target.value.trim();
        const name = e.target.name;

        switch (name) {
            case 'password':
                if (input.passwordConfirm.touched) {
                    setInput((prev) => ({
                        password: {
                            ...prev.password,
                            value,
                            invalid: false,
                        },
                        passwordConfirm: {
                            ...prev.passwordConfirm,
                            invalid: !new Validator(value).isMatch(
                                prev.passwordConfirm.value
                            ).valid,
                        },
                    }));
                } else {
                    setInput((prev) => ({
                        ...prev,
                        password: {
                            invalid: false,
                            value,
                        },
                    }));
                }
                break;
            case 'passwordConfirm':
                setInput((prev) => ({
                    ...prev,
                    passwordConfirm: {
                        ...prev.passwordConfirm,
                        value,
                        invalid: !new Validator(value).isMatch(
                            prev.password.value
                        ).valid,
                    },
                }));
                break;
            default:
                break;
        }
    };

    const handleBlurInput = (e) => {
        const name = e.target.name;
        const value = e.target.value.trim();
        switch (name) {
            case 'password':
                setInput((prev) => ({
                    ...prev,
                    password: {
                        ...prev.password,
                        touched: true,
                        invalid: !new Validator(value).minLength(8).isRequired()
                            .valid,
                    },
                }));
                break;

            case 'passwordConfirm':
                setInput((prev) => ({
                    ...prev,
                    passwordConfirm: {
                        ...prev.passwordConfirm,
                        touched: true,
                        invalid: !new Validator(value).isMatch(
                            input.password.value
                        ).valid,
                    },
                }));
                break;

            default:
                break;
        }
    };

    const validateAll = Object.keys(input)
        .map((key) => input[key])
        .every((el) => el.invalid !== undefined && el.invalid === false);

    const handleSubmit = async () => {
        if (validateAll && !isLoading) {
            setIsLoading(true);
            try {
                const res = await authApi.resetPassword({
                    OTPCode: otp,
                    password: input.password.value,
                    passwordConfirm: input.passwordConfirm.value,
                });
                if ((res.status = 200)) setStep();
            } catch (error) {
                console.log(error);
                handleErrorAsync(error, setErrorMsg);
            }
            setIsLoading(false);
        }
    };

    const wrapperEnter = (e) => {
        if (e.key === 'Enter') handleSubmit();
    };

    return (
        <div
            onKeyDown={wrapperEnter}
            className="flex-1 flex flex-col gap-10 justify-center"
        >
            <div className="flex flex-col gap-2">
                <span className="my-0 mx-auto">
                    <FontAwesomeIcon
                        className="text-2xl p-4 border rounded-lg"
                        icon={faKeyboard}
                    />
                </span>
                <p className="text-xl font-semibold"> Đặt mật khẩu mới</p>
                <span className="text-sm text-gray-500">
                    *Tối thiểu 8 ký tự
                </span>
            </div>
            {errorMsg && (
                <div>
                    <span className="text-sm text-red-500"> {errorMsg}</span>
                </div>
            )}
            <div className="flex flex-col gap-4">
                <div>
                    <label htmlFor="password">Mật khẩu mới</label>
                    <input
                        autoFocus
                        onBlur={handleBlurInput}
                        onChange={handleChangeInput}
                        disabled={isLoading}
                        name="password"
                        id="password"
                        type="password"
                        placeholder="mật khẩu mới"
                        className="p-3 w-full rounded-lg bg-slate-200 mt-1 focus-within:outline-slate-600"
                    />
                    {input.password.invalid && (
                        <span className="text-red-500 text-sm">
                            *Tối thiểu 8 ký tự
                        </span>
                    )}
                </div>
                <div>
                    <label htmlFor="passwordConfirm">Nhập lại mật khẩu</label>
                    <input
                        onBlur={handleBlurInput}
                        disabled={isLoading}
                        onChange={handleChangeInput}
                        name="passwordConfirm"
                        id="passwordConfirm"
                        type="password"
                        placeholder="mật khẩu mới"
                        className="p-3 w-full rounded-lg bg-slate-200 mt-1 focus-within:outline-slate-600"
                    />
                    {input.passwordConfirm.invalid && (
                        <span className="text-red-500 text-sm">
                            Mật khẩu không khớp
                        </span>
                    )}
                </div>
            </div>
            <button
                className={`p-2 bg-violet-500 rounded-lg text-white ${
                    isLoading && 'opacity-60 cursor-not-allowed'
                }`}
                onClick={handleSubmit}
            >
                {isLoading ? (
                    <FontAwesomeIcon
                        className="animate-spin"
                        icon={faCircleNotch}
                    />
                ) : (
                    'Đặt lại mật khẩu'
                )}
            </button>
            <ToLogin />
        </div>
    );
}

export default Step3;
