import axiosClient from './axiosClient';

const shippingUnitApi = {
    getShippingUnits: async () => {
        const baseURL = 'shipping-units';
        return await axiosClient.get(baseURL);
    },
};

export default shippingUnitApi;
