import { format, startOfDay, startOfToday } from 'date-fns';
import React, { useEffect, useState } from 'react';
import Search from '../../../../components/Search';
import DateRangePicker from '../../../../components/DatePicker/DateRangePicker';
import { useParams } from 'react-router-dom';
import orderApi from '../../../../api/orderApi';
import Pagination from '../../../../components/Pagination';

function PackingManager() {
    const params = useParams();
    const [isLoadingSearch, setIsLoadingSearch] = useState(false);
    const [orderList, setOrderList] = useState([]);
    const [totalOrder, setTotalOrder] = useState(undefined);
    //Handle Filter//
    const [filter, setFilter] = useState({
        startDate: startOfToday() - 30 * 24 * 60 * 60 * 1000,
        endDate: startOfToday().getTime(),
        page: 1,
        limit: 50,
        searchTerm: '',
    });

    const handleSelectLimit = (e) => {
        setFilter((prev) => ({
            ...prev,
            limit: e.target.value || undefined,
            page: 1,
        }));
    };

    const handeleOnChevron = (e) => {
        e.stopPropagation();
        const value = e.target.getAttribute('name');
        if (value === 'left' && filter.page - 1 > 0) {
            setFilter((prev) => ({ ...prev, page: prev.page - 1 }));
        }

        if (
            value === 'right' &&
            filter.page + 1 <= Math.ceil(totalOrder / filter.limit)
        ) {
            setFilter((prev) => ({ ...prev, page: prev.page + 1 }));
        }
    };

    const handleSearch = (value) => {
        setFilter((prev) => ({
            ...prev,
            page: 1,
            searchTerm: value,
        }));
    };

    console.log(totalOrder);

    //Call API Order List
    useEffect(() => {
        const queryObj = { ...filter };
        Object.keys(queryObj).forEach((el) => {
            if (!queryObj[el]) delete queryObj[el];
        });
        const queryString = Object.keys(queryObj)
            .map((el) => {
                switch (el) {
                    case 'startDate':
                        return `createAt[gte]=${new Date(
                            format(queryObj[el], 'yyyy-MM-dd')
                        ).getTime()}`;
                    case 'endDate':
                        return `createAt[lte]=${new Date(
                            format(queryObj[el], 'yyyy-MM-dd') + 'T23:59:59'
                        ).getTime()}`;
                    case 'searchTerm':
                        return `orderNumber[regex]=${queryObj[
                            el
                        ].toLowerCase()}`;
                    default:
                        return `${el}=${queryObj[el]}`;
                }
            })
            .join('&');

        const fetchOrder = async () => {
            if (filter.searchTerm) setIsLoadingSearch(true);
            try {
                const { data } = await orderApi.getPackingOrders(
                    params.shopId,
                    queryString
                );
                console.log(data);
                setOrderList(data.data);
                setTotalOrder(data.totalDocs);
            } catch (error) {
                console.log(error);
            }
            setIsLoadingSearch(false);
        };
        fetchOrder();
    }, [params, filter]);

    const convertTimeToDurationTime = (time) => {
        const currentTime = new Date(time).getTime();
        const morning = startOfDay(new Date(time)).getTime();
        const afternoon = morning + 12 * 60 * 60 * 1000;
        const night = morning + 18 * 60 * 60 * 1000;
        if (currentTime >= night) return 'Tối';
        if (currentTime >= afternoon) return 'Chiều';
        return 'Sáng';
    };
    return (
        <div className="h-full w-full flex flex-col lg:w-[1280px]">
            <div className="flex justify-between">
                <div>
                    <Search
                        onChange={handleSearch}
                        isLoading={isLoadingSearch}
                    />
                </div>
                <div className="border w-60 rounded-lg hover:border-slate-600">
                    <DateRangePicker
                        dateRange={{
                            startDate: filter.startDate,
                            endDate: filter.endDate,
                        }}
                        onChange={setFilter}
                    />
                </div>
            </div>
            <div className="overflow-auto h-full">
                <table className="w-full">
                    <thead>
                        <tr className="text-left grid grid-cols-12">
                            <th className="col-span-1">No.</th>
                            <th className="col-span-4">Mã vận đơn</th>
                            <th className="col-span-5">Ngày tạo</th>
                            <th className="col-span-2">Ca</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orderList.map((el, index) => (
                            <tr
                                key={index}
                                className="text-left grid grid-cols-12 border-b p-1 last:border-none hover:bg-slate-200"
                            >
                                <td className="col-span-1">#{index + 1}</td>
                                <td className="col-span-4">
                                    {el.orderNumber.toUpperCase()}
                                </td>
                                <td className="col-span-5">
                                    {format(
                                        new Date(el.createAt),
                                        'dd-MM-yyyy HH:mm:ss'
                                    )}
                                </td>
                                <td className="col-span-2">
                                    {convertTimeToDurationTime(el.createAt)}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Pagination
                onChevron={handeleOnChevron}
                totalItem={totalOrder}
                onChangeLimit={handleSelectLimit}
                limit={filter.limit}
                page={filter.page}
            />
        </div>
    );
}

export default PackingManager;
