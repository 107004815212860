import { faEraser, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useNavigate, useOutletContext } from 'react-router-dom';
import ChangeUserPassword from './ChangeUserPassword';
import ChangeUserInfo from './ChangeUserInfo';

function UserSetting() {
    const navigate = useNavigate();
    const { userInfo, setUserInfo } = useOutletContext();
    const [openChangePassword, setOpenChangePassword] = useState(false);
    const [openChangeInfo, setOpenChangeInfo] = useState(false);
    const handleLogOut = () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('user');
        navigate('/login');
    };

    const notifySuccess = (successMsg) =>
        toast.success(successMsg || 'Thành công');
    return (
        <div className="bg-white relative shadow-lg min-w-[450px] rounded-lg flex flex-col gap-5 justify-center items-center p-5">
            <ToastContainer />
            <div className="bg-violet-100 overflow-auto text-2xl w-20 aspect-square flex items-center justify-center rounded-full">
                {userInfo?.avatar ? (
                    <img
                        src={`${process.env.REACT_APP_API_URL}/img/users/${userInfo.avatar}`}
                        alt="avatar"
                    />
                ) : (
                    <FontAwesomeIcon icon={faUser} />
                )}
            </div>
            <div className="w-full flex flex-col gap-5">
                <div className="flex gap-x-20 justify-between">
                    <span className="text-left">ID:</span>
                    <span className="text-right">
                        {userInfo?._id || 'user ID'}
                    </span>
                </div>
                <div className="flex gap-x-20 justify-between">
                    <span className="text-left">Tên:</span>
                    <div className="gap-2 flex items-center">
                        <span className="text-right">
                            {userInfo?.name || 'user name'}
                        </span>
                        <FontAwesomeIcon
                            onClick={() => setOpenChangeInfo(true)}
                            className="text-gray-600 hover:text-slate-900"
                            icon={faEraser}
                        />
                    </div>
                </div>
                <div className="flex gap-x-20 justify-between">
                    <span className="text-left">Email:</span>
                    <div className="gap-2 flex items-center">
                        <span className="text-right">
                            {userInfo?.email || 'user email'}
                        </span>
                        <FontAwesomeIcon
                            className="text-gray-600 hover:text-slate-900"
                            icon={faEraser}
                        />
                    </div>
                </div>
                <div className="flex gap-x-20 justify-between">
                    <span className="text-left">Mật khẩu:</span>
                    <div className="gap-2 flex items-center">
                        <span className="text-right">********</span>
                        <FontAwesomeIcon
                            onClick={() => setOpenChangePassword(true)}
                            className="text-gray-600 hover:text-slate-900"
                            icon={faEraser}
                        />
                    </div>
                </div>
            </div>
            <div className="mt-8">
                <button
                    onClick={handleLogOut}
                    className="p-2 bg-violet-500 rounded-lg text-white active:opacity-60 hover:shadow-lg"
                >
                    Đăng xuất
                </button>
            </div>
            {openChangePassword && (
                <ChangeUserPassword
                    setOpenChangePassword={setOpenChangePassword}
                    notifySuccess={notifySuccess}
                />
            )}
            {openChangeInfo && (
                <ChangeUserInfo
                    notifySuccess={notifySuccess}
                    user={userInfo}
                    setUser={setUserInfo}
                    setOpen={setOpenChangeInfo}
                />
            )}
        </div>
    );
}

export default UserSetting;
