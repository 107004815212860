import { useOutletContext, useParams } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import Tippy from '@tippyjs/react';
import { write, utils } from 'xlsx';

import Button from '../../../components/Button/Button';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faAdd,
    faBoxOpen,
    faCheckCircle,
    faEllipsis,
    faSpinner,
    faTrashCan,
    faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import orderApi from '../../../api/orderApi';
import Modal from '../../../components/Modal/Modal';
import YesNo from '../../../components/Popup/YesNo';
import CircleLoader from '../../../components/Loader/CircleLoader';
import AlertBox from '../../../components/Popup/AlertBox';
import { format } from 'date-fns';
import UploadBtn from '../../../components/UploadBtn';
import UploadOrderForm from '../../../components/UploadOrderForm';
import handleErrorAsync from '../../../utils/handleCatchAsync';
import shopApi from '../../../api/shopApi';
import cautionShip from '../../../utils/cautionShip';

function OrderPacked() {
    const audioRef = useRef();
    const errorAudioRef = useRef();
    const { shippingUnit } = useOutletContext();
    const [selected, setSelected] = useState(null);
    const [input, setInput] = useState('');
    const [orderList, setOrderList] = useState(
        JSON.parse(localStorage.getItem('ORDER_LIST')) || []
    );
    const [orderExisted, setOrderExisted] = useState(
        JSON.parse(localStorage.getItem('ORDER_EXISTED')) || []
    );
    const [inputValid, setInputValid] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [kindOfModal, setKindOfModal] = useState('yesno');
    const [orderApiMsg, setOrderApiMsg] = useState('');
    const [file, setFile] = useState();
    const [loadingUpload, setLoadingUpload] = useState(false);
    const params = useParams();
    const ORDER_LIST = 'ORDER_LIST';
    const ORDER_EXISTED = 'ORDER_EXISTED';

    useEffect(() => {
        localStorage.setItem(ORDER_LIST, JSON.stringify(orderList));
        localStorage.setItem(ORDER_EXISTED, JSON.stringify(orderExisted));
    }, [orderList, orderExisted]);

    useEffect(() => {
        if (file) {
            setOpenModal(true);
            setKindOfModal('upload');
        }
    }, [file]);

    const dataFilter = orderList.map((order) => {
        return {
            ...order,
            isExisted: orderExisted
                .map((item) => item.orderNumber)
                .includes(order.orderNumber),
            isDuplicate:
                orderList.filter(
                    (item) => item.orderNumber === order.orderNumber
                ).length > 1,
        };
    });

    const selectOptions = shippingUnit.map((item) => ({
        value: item.value,
        label: item.name,
        id: item._id,
    }));

    const handleAdd = async (e) => {
        if (!input.length) return;

        let caution = false;

        if (e.key === 'Enter' || e.type === 'click') {
            if (!selected) {
                handleErrorAudio();
                return setInputValid(false);
            }

            if (
                cautionShip[selected.value]
                    ? !cautionShip[selected.value]?.some((el) =>
                          input.toLocaleLowerCase().startsWith(el)
                      )
                    : false
            ) {
                caution = true;
                handleErrorAudio();
            } else {
                audioRef.current.load();
                audioRef.current.play();
            }
            setOrderList((prev) => [
                {
                    orderNumber: input.toLocaleLowerCase(),
                    shippingUnit: selected,
                    isDuplicate: false,
                    isExisted: false,
                    isLoading: true,
                    caution,
                },
                ...prev,
            ]);

            setInput('');

            const finishLoad = () => {
                setOrderList((prev) => {
                    return prev.map((order) => {
                        return { ...order, isLoading: false };
                    });
                });
            };

            if (
                orderExisted
                    .map((order) => order.orderNumber)
                    .includes(input.toLowerCase())
            ) {
                return finishLoad();
            }

            if (
                orderList
                    .map((order) => order.orderNumber)
                    .includes(input.toLowerCase())
            ) {
                if (!caution) {
                    handleErrorAudio();
                }
                return finishLoad();
            }
            try {
                const response = await orderApi.findOrder(params.shopId, input);
                if (response.status === 200) {
                    setOrderExisted((prev) => [...prev, response.data?.data]);
                }
                finishLoad();
            } catch (error) {
                console.log(error);
                setKindOfModal('error');
                setOpenModal(true);
                setOrderApiMsg(error.response.data.message);
                finishLoad();
            }
        }
    };

    const handleErrorAudio = () => {
        errorAudioRef.current.load();
        errorAudioRef.current.play();
    };

    const handleDelete = (id) => {
        setOrderList((prev) => {
            return prev.filter((order, index) => index !== id);
        });
    };

    const checkAllOrder =
        dataFilter.every((order) => {
            return !order.isDuplicate && !order.isExisted;
        }) && dataFilter.length > 0;

    const handleSubmit = async () => {
        if (checkAllOrder) {
            //Call API
            setKindOfModal('loading');
            const dataRequest = dataFilter.map((order) => {
                return {
                    orderNumber: order.orderNumber,
                    shippingUnit: order.shippingUnit.id,
                };
            });

            try {
                const { data } = await orderApi.newOrderPacked(
                    params.shopId,
                    dataRequest
                );

                console.log(data);
                setOrderApiMsg(
                    `Đã tạo thành công ${data.data.length} / ${dataFilter.length} đơn hàng`
                );

                //remove localStorage
                localStorage.removeItem(ORDER_EXISTED);
                localStorage.removeItem(ORDER_LIST);
                setOrderExisted([]);
                setOrderList([]);
                setSelected(null);
                setKindOfModal('success');
            } catch (error) {
                console.log(error);
                setKindOfModal('error');
                setOrderApiMsg(
                    'Đã có lỗi xảy ra, vui lỏng thử lại sau ít phút'
                );
            }
        }
    };

    const handleCloseModal = () => {
        setKindOfModal('yesno');
        setOpenModal(false);
    };

    const handleYesNo = () => {
        if (checkAllOrder) setOpenModal(true);
    };

    const handleSelect = (value) => {
        setInputValid(true);
        setSelected(value);
    };

    const handleSetFile = (value) => {
        setFile(value);
    };

    const handleUpload = async () => {
        setLoadingUpload(true);
        setOrderApiMsg('');
        try {
            const form = new FormData();
            form.append('xlsx', file);
            const { data } = await orderApi.importOrderPacked(
                params.shopId,
                form
            );
            setOrderApiMsg(
                `Tải lên thành công ${data.data.orderCounter} đơn hàng`
            );
        } catch (error) {
            console.log(error);
            handleErrorAsync(error, setOrderApiMsg);
        }
        setLoadingUpload(false);
    };

    const handleCloseUpload = () => {
        setFile('');
        setOpenModal(false);
        setKindOfModal('yesno');
        setOrderApiMsg('');
    };

    const handleDownloadExample = async () => {
        try {
            const { data } = await shopApi.examplePacked();
            const wookbook = utils.book_new();
            const wooksheet = utils.json_to_sheet(data.data);
            utils.book_append_sheet(wookbook, wooksheet, 'DON_HANG');
            const newFILE = write(wookbook, {
                type: 'buffer',
                bookType: 'xlsx',
            });

            //convert to url a
            const url = window.URL.createObjectURL(new Blob([newFILE]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `example-order-packed.xlsx`);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            setOpenModal(true);
            setKindOfModal('error');
            handleErrorAsync(error, setOrderApiMsg);
        }
    };
    return (
        <div className="h-full w-full flex justify-center overflow-auto">
            <audio ref={audioRef}>
                <source
                    type="audio/mpeg"
                    src="/audio/barcode_sound.mp3"
                    hidden
                />
            </audio>
            <audio ref={errorAudioRef}>
                <source
                    type="audio/mpeg"
                    src="/audio/error-sound2.mp3"
                    hidden
                />
            </audio>
            <div className="flex w-full 2xl:w-[1280px] flex-col justify-between items-center gap-10 h-full p-5 ">
                <div className="w-full gap-5 flex justify-between items-center">
                    <div className="flex flex-1 gap-3">
                        <div className="flex-1">
                            <input
                                onKeyDown={handleAdd}
                                className=" p-6 h-4 w-full bg-gray-300  outline-none rounded-lg"
                                placeholder="Nhập mã vận đơn"
                                type="text"
                                value={input}
                                onChange={(e) =>
                                    setInput(e.target.value.trim())
                                }
                            />
                        </div>
                        <div className="flex">
                            <Button
                                onClick={handleAdd}
                                backgroundColor="black"
                                rounder
                                width={50}
                            >
                                <FontAwesomeIcon icon={faAdd} />
                            </Button>
                        </div>
                    </div>
                    <div className={`h-full aspect-square relative`}>
                        <UploadBtn
                            onDownLoadExample={handleDownloadExample}
                            setFile={handleSetFile}
                            file={file}
                        />
                    </div>
                    <div className={`${!inputValid && 'animate-shake'}`}>
                        <Select
                            onChange={handleSelect}
                            placeholder="Chọn đơn vị vận chuyển"
                            options={selectOptions}
                            value={selected}
                            isClearable
                            styles={{
                                control: (baseStyle, state) => ({
                                    ...baseStyle,
                                    height: 48,
                                    width: 260,
                                    borderRadius: '8px',
                                    borderColor: inputValid ? 'gray' : 'red',
                                    boxShadow: 'black',
                                    ':hover': {
                                        borderColor: 'black',
                                    },
                                }),
                                option: (baseStyle, state) => ({
                                    ...baseStyle,
                                    background: state.isSelected
                                        ? '#1f2937'
                                        : 'unset',
                                    ':hover': {
                                        background: '#e2e8f0',
                                    },
                                }),
                            }}
                        />
                    </div>
                </div>

                <div className="bg-white flex flex-1 flex-col shadow-lg h-full w-full rounded-lg overflow-auto">
                    <table className="w-full grid border-collapse">
                        <thead className="sticky top-0 bg-slate-300">
                            <tr className="grid grid-cols-12 p-2 border-[0.5px]">
                                <th className="text-start col-span-1">STT</th>
                                <th className="text-start col-span-4">
                                    Mã vận đơn
                                </th>
                                <th className="text-start col-span-3">
                                    Nhà vận chuyển
                                </th>
                                <th className=" col-span-3">Trạng thái</th>
                                <th className=" col-span-1">
                                    <FontAwesomeIcon icon={faEllipsis} />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataFilter.map((order, index) => (
                                <tr
                                    className="hover:bg-slate-100 grid grid-cols-12 p-2 border-[0.5px] items-center"
                                    key={index}
                                >
                                    <td className="col-span-1">{index + 1}</td>
                                    <td className="col-span-4">
                                        <span className="flex gap-1 items-center">
                                            <span>
                                                {order.orderNumber.toUpperCase()}
                                            </span>
                                            {order.caution && (
                                                <FontAwesomeIcon
                                                    className="text-orange-400"
                                                    icon={faTriangleExclamation}
                                                />
                                            )}
                                        </span>
                                    </td>
                                    <td className="col-span-3">
                                        {order.shippingUnit.label}
                                    </td>
                                    <td className="col-span-3">
                                        {order.isLoading ? (
                                            <span className="flex justify-center items-center animate-spin">
                                                <FontAwesomeIcon
                                                    icon={faSpinner}
                                                />
                                            </span>
                                        ) : (
                                            <div className="flex justify-center cursor-default items-center flex-col gap-1">
                                                {order.isDuplicate && (
                                                    <span className="text-sm w-24 text-center bg-orange-100 text-orange-500 px-2 rounded-2xl">
                                                        Trùng đơn
                                                    </span>
                                                )}
                                                {order.isExisted && (
                                                    <Tippy
                                                        content={format(
                                                            new Date(
                                                                orderExisted.find(
                                                                    (el) =>
                                                                        el.orderNumber ===
                                                                        order.orderNumber
                                                                )?.createAt
                                                            ),
                                                            'dd/MM/yyyy HH:mm:ss'
                                                        )}
                                                    >
                                                        <span className="text-sm w-24 text-center bg-red-100 text-red-500 px-2 rounded-2xl">
                                                            Đã tạo
                                                        </span>
                                                    </Tippy>
                                                )}
                                                {!order.isDuplicate &&
                                                    !order.isExisted && (
                                                        <span className="text-green-600">
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faCheckCircle
                                                                }
                                                            />
                                                        </span>
                                                    )}
                                            </div>
                                        )}
                                    </td>
                                    <td className="col-span-1 text-center">
                                        <span
                                            className="p-2 rounded-full text-gray-600 hover:text-gray-950"
                                            onClick={() => handleDelete(index)}
                                        >
                                            <FontAwesomeIcon
                                                icon={faTrashCan}
                                            />
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {dataFilter.length === 0 && (
                        <div className="flex-1 gap-4 flex justify-center items-center">
                            <span className="text-slate-300 text-[60px]">
                                <FontAwesomeIcon icon={faBoxOpen} />
                            </span>
                        </div>
                    )}
                </div>
                <div className="w-full flex justify-end items-center">
                    <div className="h-16 w-28 flex">
                        <Button
                            onClick={handleYesNo}
                            disable={!checkAllOrder}
                            rounder
                            fontWeight="600"
                        >
                            TẠO ĐƠN
                        </Button>
                    </div>
                </div>
            </div>
            <Modal isOpen={openModal}>
                {kindOfModal === 'yesno' && (
                    <YesNo
                        action={handleSubmit}
                        setOpenModal={handleCloseModal}
                        yes="Xác nhận"
                        no="Huỷ"
                        title="Bạn đang thực hiện tạo đơn"
                    />
                )}
                {kindOfModal === 'loading' && <CircleLoader />}
                {kindOfModal === 'success' && (
                    <AlertBox
                        type={kindOfModal}
                        onClose={handleCloseModal}
                        message={orderApiMsg}
                    />
                )}
                {kindOfModal === 'error' && (
                    <AlertBox
                        type={kindOfModal}
                        onClose={handleCloseModal}
                        message={orderApiMsg}
                    />
                )}
                {kindOfModal === 'upload' && (
                    <UploadOrderForm
                        title="Tải lên đơn hàng đã đóng gói"
                        file={file}
                        onSubmit={handleUpload}
                        message={orderApiMsg}
                        onClose={handleCloseUpload}
                        isLoading={loadingUpload}
                    />
                )}
            </Modal>
        </div>
    );
}

export default OrderPacked;
