import {
    faCheckCircle,
    faXmarkCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Button from '../Button/Button';

function AlertBox({ type, message, onClose, countdown }) {
    if (countdown) {
        setTimeout(() => {
            onClose();
        }, countdown * 1000);
    }

    return (
        <div className="animate-zoomIn w-80 p-10 flex flex-col justify-between items-center gap-8 bg-white rounded-lg shadow-md">
            <span className="text-6xl">
                {type === 'error' ? (
                    <FontAwesomeIcon icon={faXmarkCircle} />
                ) : (
                    <FontAwesomeIcon icon={faCheckCircle} />
                )}
            </span>

            <p className="w-full  text-center break-words">
                {message || 'No message'}
            </p>

            <div className="flex w-40">
                <Button onClick={onClose} border rounder>
                    <span>Đóng</span>
                </Button>
            </div>
        </div>
    );
}

export default AlertBox;
