import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPieChart, faRotateLeft } from '@fortawesome/free-solid-svg-icons';
import convertRatio from '../../../utils/convertRatioToText';

function ReturnedPercent({ returning, orderThisMonth }) {
    let dataReturning = 0;
    let thisMonth = 0;
    // let lastMonth = 0;
    let thisMonthCancelled = 0;
    // let lastMonthCancelled = 0;

    if (orderThisMonth && orderThisMonth.length > 0) {
        thisMonth = orderThisMonth[0].thisMonth;
        // lastMonth = orderThisMonth[0].lastMonth;
        thisMonthCancelled = orderThisMonth[0].thisMonthCancelled;
        // lastMonthCancelled = orderThisMonth[0].lastMonthCancelled;
    }

    const returnRatio = (thisMonthCancelled * 100) / thisMonth;
    // const returnRatioCompareLastMonth =
    //     (100 * (thisMonthCancelled - lastMonthCancelled)) / lastMonthCancelled;

    if (returning && returning.length > 0)
        dataReturning = returning[0].returning;
    return (
        <div className="px-5 flex flex-col gap-5">
            <span className=" font-semibold">Báo cáo đơn hoàn</span>
            <div className=" bg-orange-600 rounded-lg h-24 flex justify-between items-center gap-5">
                <span className="text-5xl ml-5 text-white flex-1">
                    <FontAwesomeIcon icon={faPieChart} />
                </span>

                <div className="h-full flex-1 flex flex-col gap-2 justify-center items-start">
                    <span className="text-white text">Đang hoàn</span>
                    <div className="flex items-center justify-center gap-2">
                        <p className="text-white font-semibold">
                            {dataReturning}
                        </p>
                        <span className="text-white">
                            <FontAwesomeIcon icon={faRotateLeft} />
                        </span>
                    </div>
                </div>
                <div className="h-full flex-1 flex flex-col gap-2 justify-center items-start">
                    <span className="text-white">Tỉ lệ hoàn</span>
                    <div className="flex items-center justify-center gap-2">
                        <span className="text-white font-semibold flex gap-2">
                            {convertRatio(returnRatio)}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReturnedPercent;
