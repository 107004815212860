import React from 'react';

function Notes({ notes }) {
    return (
        <div className="overflow-auto flex gap-2 whitespace-nowrap">
            {notes?.map((note, index) => (
                <span
                    className="bg-violet-300 rounded-lg p-[1px] cursor-default"
                    key={index}
                >{`#${index + 1}: ${note.note}`}</span>
            ))}
        </div>
    );
}

export default Notes;
