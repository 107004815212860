import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';

function UploadBtn({ file, setFile, onDownLoadExample }) {
    const inputRef = useRef();
    const [openMenu, setOpenMenu] = useState(false);
    const handleBrowse = () => {
        inputRef.current.click();
    };
    const handleSetFile = (e) => {
        setFile(e.target.files[0]);
    };

    useEffect(() => {
        if (!file) {
            inputRef.current.value = null;
        }
    }, [file]);

    return (
        <div
            tabIndex={0}
            onClick={() => setOpenMenu((prev) => !prev)}
            onBlur={() => setOpenMenu(false)}
            className="cursor-default relative w-full h-full border rounded-lg flex gap-2 justify-center items-center  border-slate-500"
        >
            {openMenu && (
                <ul
                    onClick={(e) => e.stopPropagation()}
                    className="absolute top-full right-0 border rounded-lg mt-2 z-20 w-32 bg-white shadow-lg"
                >
                    <li
                        onClick={handleBrowse}
                        className="border-b hover:bg-slate-200 p-2"
                    >
                        Tải lên
                    </li>
                    <li
                        onClick={onDownLoadExample}
                        className="hover:bg-slate-200 p-2"
                    >
                        File mẫu
                    </li>
                </ul>
            )}
            <span>
                <FontAwesomeIcon icon={faCloudArrowUp} />
            </span>

            <input
                onClick={(e) => e.stopPropagation()}
                onChange={handleSetFile}
                hidden
                ref={inputRef}
                type="file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
        </div>
    );
}

export default UploadBtn;
