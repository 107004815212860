import { faBox } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

function OrderToday({ orderToday }) {
    const dataList = orderToday.map((el) => ({
        shippingUnit: el.shippingUnit?.name || '',
        numsOrder: el.numsOrder,
    }));
    const totalOrder = dataList.reduce((acc, cur) => {
        return acc + cur.numsOrder;
    }, 0);
    return (
        <div className="row-span-2 flex flex-col h-full overflow-auto">
            <span className=" font-semibold text-slate-700">
                <span>{`Đơn hàng mới: ${totalOrder}`}</span>{' '}
                <FontAwesomeIcon icon={faBox} />
            </span>
            <div className="overflow-auto">
                <table className="w-full border-separate border-spacing-y-2">
                    <thead className="sticky top-0 bg-zinc-50 z-10">
                        <tr className="text-left text-sm text-gray-400">
                            <th className="p-2">STT</th>
                            <th className="p-2">Nhà vận chuyển</th>
                            <th className="p-2">Số lượng đơn</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataList.map((el, index) => (
                            <tr
                                key={index}
                                className="bg-white rounded-lg drop-shadow-md"
                            >
                                <td className="p-2 rounded-l-lg">{`#${
                                    index + 1
                                }`}</td>
                                <td className="p-2">{el.shippingUnit}</td>
                                <td className="p-2 rounded-r-lg">
                                    {el.numsOrder}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default OrderToday;
