import React, { useState } from 'react';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import StepBar from './StepBar';

function ForgotPassword() {
    const [step, setStep] = useState(1);
    const [otp, setOtp] = useState('');

    const handleChangeInputOtp = (e) => {
        setOtp(e.target.value);
    };

    const handelNextStep = () => {
        setStep((prev) => prev + 1);
    };

    return (
        <div className="w-full flex flex-colx justify-center items-center h-screen bg-zinc-50">
            <div className="flex flex-col h-full justify-end p-5 gap-5 w-full sm:w-96">
                {step === 1 && <Step1 setStep={handelNextStep} step={step} />}
                {step === 2 && (
                    <Step2
                        step={step}
                        setOtp={handleChangeInputOtp}
                        otp={otp}
                        setStep={handelNextStep}
                    />
                )}
                {step === 3 && <Step3 setStep={handelNextStep} otp={otp} />}
                {step === 4 && <Step4 setStep={handelNextStep} />}
                <StepBar step={step} />
            </div>
        </div>
    );
}

export default ForgotPassword;
