import axiosClient from './axiosClient';

const usersApi = {
    getUserByEmail: async (email) => {
        const baseURL = `users/findUser?email=${email}`;
        return await axiosClient.get(baseURL);
    },
    getMe: () => {
        const baseURL = `users/me`;
        return axiosClient.get(baseURL);
    },
    changePassword: (currentPassword, password, passwordConfirm) => {
        const baseURL = `users/change-password`;
        return axiosClient.patch(baseURL, {
            currentPassword,
            password,
            passwordConfirm,
        });
    },
    updateMe: (formData) => {
        const baseURL = `users/update-me`;
        return axiosClient.patch(baseURL, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },
};

export default usersApi;
