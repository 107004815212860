const convertOrderStatus = (status) => {
    let changedStatus;

    switch (status) {
        case 'returning':
            changedStatus = 'Đang hoàn';
            break;
        case 'returned':
            changedStatus = 'Đã hoàn';
            break;
        case 'packed':
            changedStatus = 'Đóng gói';
            break;
        default:
            break;
    }
    return changedStatus;
};

export default convertOrderStatus;
