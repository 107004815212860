import React, { useEffect, useState } from 'react';
import Search from '../../../components/Search';
import Select from 'react-select';
import DateRangePicker from '../../../components/DatePicker/DateRangePicker';
import { format, startOfToday } from 'date-fns';
import { useOutletContext, useParams } from 'react-router-dom';
import { write, utils } from 'xlsx';

import orderApi from '../../../api/orderApi';
import Pagination from '../../../components/Pagination';
import ReturnedManagerTable from './ReturnedManagerTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

function ReturnedManager() {
    const { shippingUnit } = useOutletContext();
    const params = useParams();
    const [orderList, setOrderList] = useState([]);
    const [totalOrder, setTotalOrder] = useState(undefined);
    const [isLoadingSearch, setIsLoadingSearch] = useState(false);
    const [querytStr, setQueryStr] = useState('');
    //Handle Filter//
    const [filter, setFilter] = useState({
        shippingUnit: null,
        status: 'returned',
        startDate: startOfToday() - 30 * 24 * 60 * 60 * 1000,
        endDate: startOfToday().getTime(),
        page: 1,
        limit: 50,
        searchTerm: '',
    });

    const handleSelectLimit = (e) => {
        setFilter((prev) => ({
            ...prev,
            limit: e.target.value || undefined,
            page: 1,
        }));
    };

    const handeleOnChevron = (e) => {
        e.stopPropagation();
        const value = e.target.getAttribute('name');
        if (value === 'left' && filter.page - 1 > 0) {
            setFilter((prev) => ({ ...prev, page: prev.page - 1 }));
        }

        if (
            value === 'right' &&
            filter.page + 1 <= Math.ceil(totalOrder / filter.limit)
        ) {
            setFilter((prev) => ({ ...prev, page: prev.page + 1 }));
        }
    };

    const shippingUnitOptions = shippingUnit.map((item) => ({
        value: item.value,
        label: item.name,
        id: item._id,
    }));

    const handeSelectShippingUnit = (value) => {
        setFilter((prev) => ({
            ...prev,
            shippingUnit: value || null,
            page: 1,
        }));
    };

    const handleSearch = (value) => {
        setFilter((prev) => ({
            ...prev,
            status: null,
            shippingUnit: null,
            page: 1,
            searchTerm: value,
        }));
    };

    const handleExport = () => {
        //1, create File XLSX
        const dataToSheet = orderList.map((order) => ({
            orderNumber: order.orderNumber.toUpperCase(),
            shippingUnit: order.shippingUnit.name,
            status: order.status,
            notes: order.notes
                .map((note) => `${note.createBy.name}: ${note.note}`)
                .join(' | '),
            createAt: new Date(order.createAt),
        }));
        const wookbook = utils.book_new();
        const wooksheet = utils.json_to_sheet(dataToSheet);
        utils.book_append_sheet(wookbook, wooksheet, 'DON_HANG');
        const newFILE = write(wookbook, {
            type: 'buffer',
            bookType: 'xlsx',
        });

        //convert to url a
        const url = window.URL.createObjectURL(new Blob([newFILE]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            `${format(new Date(), 'yyyyMMdd')}-returned-${querytStr}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
    };

    //Call API Order List
    useEffect(() => {
        const queryObj = { ...filter };
        Object.keys(queryObj).forEach((el) => {
            if (!queryObj[el]) delete queryObj[el];
        });
        const queryString = Object.keys(queryObj)
            .map((el) => {
                switch (el) {
                    case 'startDate':
                        return `returnedAt[gte]=${new Date(
                            format(queryObj[el], 'yyyy-MM-dd')
                        ).getTime()}`;
                    case 'endDate':
                        return `returnedAt[lte]=${new Date(
                            format(queryObj[el], 'yyyy-MM-dd') + 'T23:59:59'
                        ).getTime()}`;
                    case 'searchTerm':
                        return `orderNumber[regex]=${queryObj[
                            el
                        ].toLowerCase()}`;
                    case 'shippingUnit':
                        return `${el}=${queryObj[el].id}`;
                    case 'status':
                        return `${el}=${queryObj[el]}`;
                    default:
                        return `${el}=${queryObj[el]}`;
                }
            })
            .join('&');

        setQueryStr(queryString);
        const fetchOrder = async () => {
            if (filter.searchTerm) setIsLoadingSearch(true);
            try {
                const { data } = await orderApi.getAllOrder(
                    params.shopId,
                    queryString
                );
                setOrderList(
                    data.data.map((order) => ({ ...order, isOpenMenu: false }))
                );
                setTotalOrder(data.totalDocs);
            } catch (error) {
                console.log(error);
            }
            setIsLoadingSearch(false);
        };
        fetchOrder();
    }, [params, filter]);

    return (
        <div className="h-full w-full overflow-auto">
            <div className="w-full h-full flex flex-col min-w-[776px] gap-5">
                <div className="flex h-9 justify-between gap-5">
                    <div className="flex-1">
                        <Search
                            placeholder="Tìm đơn hàng"
                            isLoading={isLoadingSearch}
                            value={filter.searchTerm}
                            onChange={handleSearch}
                        />
                    </div>
                    <div className="z-20 w-[260px]">
                        <Select
                            isClearable
                            value={filter.shippingUnit}
                            options={shippingUnitOptions}
                            onChange={handeSelectShippingUnit}
                            placeholder="Vận chuyển"
                            styles={{
                                control: (baseStyle, state) => ({
                                    ...baseStyle,
                                    borderRadius: '8px',
                                    boxShadow: 'black',
                                    ':hover': {
                                        borderColor: 'black',
                                    },
                                }),
                                option: (baseStyle, state) => ({
                                    ...baseStyle,
                                    background: state.isSelected
                                        ? '#1f2937'
                                        : 'unset',
                                    ':hover': {
                                        background: '#e2e8f0',
                                    },
                                }),
                            }}
                        />
                    </div>
                    <div className="w-60 border rounded-lg h-full hover:border-slate-800">
                        <DateRangePicker
                            dateRange={{
                                startDate: filter.startDate,
                                endDate: filter.endDate,
                            }}
                            onChange={setFilter}
                        />
                    </div>
                    <div
                        onClick={handleExport}
                        className="border rounded-lg px-6 cursor-pointer hover:border-slate-600"
                    >
                        <div className="h-full w-full flex items-center justify-center gap-2">
                            <FontAwesomeIcon icon={faDownload} />
                            <span>Xuất</span>
                        </div>
                    </div>
                </div>
                <ReturnedManagerTable orderList={orderList} />
                <Pagination
                    onChevron={handeleOnChevron}
                    totalItem={totalOrder}
                    onChangeLimit={handleSelectLimit}
                    limit={filter.limit}
                    page={filter.page}
                />
            </div>
        </div>
    );
}

export default ReturnedManager;
