const cautionShip = {
    spx: ['spx'],
    jnt: ['8'],
    ghtk: ['3'],
    ghn: ['g'],
    lex: ['lmp', 'best', 'jnt'],
    njv: ['spe'],
};

export default cautionShip;
