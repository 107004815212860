import React, { useEffect, useRef, useState } from 'react';
import { format } from 'date-fns';
import Button from '../../../components/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faAdd,
    faBoxOpen,
    faCheckCircle,
    faEllipsis,
    faSpinner,
    faTrashCan,
    faXmarkCircle,
} from '@fortawesome/free-solid-svg-icons';
import orderApi from '../../../api/orderApi';
import { useParams } from 'react-router-dom';
import Modal from '../../../components/Modal/Modal';
import YesNo from '../../../components/Popup/YesNo';
import AlertBox from '../../../components/Popup/AlertBox';
import CircleLoader from '../../../components/Loader/CircleLoader';
import UploadBtn from '../../../components/UploadBtn';
import UploadOrderForm from '../../../components/UploadOrderForm';
import handleErrorAsync from '../../../utils/handleCatchAsync';
import shopApi from '../../../api/shopApi';
import { utils, write } from 'xlsx';

function OrderReturned() {
    const audioRef = useRef();
    const errorAudioRef = useRef();

    const params = useParams();
    const RETURNED_LIST = 'RETURNED_LIST';
    const ORDER_EXISTED_RETURNED = 'ORDER_EXISTED_RETURNED';
    const [input, setInput] = useState('');
    const [returnedList, setReturnedList] = useState(
        JSON.parse(localStorage.getItem(RETURNED_LIST)) || []
    );
    const [orderExisted, setOrderExisted] = useState(
        JSON.parse(localStorage.getItem(ORDER_EXISTED_RETURNED)) || []
    );
    const [openModal, setOpenModal] = useState(false);
    const [kindOfModal, setKindOfModal] = useState('yesno');
    const [orderApiMsg, setOrderApiMsg] = useState();
    const [file, setFile] = useState();
    const [loadingUpload, setLoadingUpload] = useState(false);

    useEffect(() => {
        localStorage.setItem(RETURNED_LIST, JSON.stringify(returnedList));
        localStorage.setItem(
            ORDER_EXISTED_RETURNED,
            JSON.stringify(orderExisted)
        );
    }, [returnedList, orderExisted]);

    useEffect(() => {
        if (file) {
            setOpenModal(true);
            setKindOfModal('upload');
        }
    }, [file]);

    const handleAdd = async (e) => {
        if (!input.length) return;

        if (e.key === 'Enter' || e.type === 'click') {
            setReturnedList((prev) => [
                {
                    orderNumber: input,
                    shippingUnit: undefined,
                    createAt: undefined,
                    isExisted: false,
                    isDuplicate: false,
                    isReturned: false,
                    isLoading: true,
                },
                ...prev,
            ]);
            //Sound on Add
            audioRef.current.load();
            audioRef.current.play();

            setInput('');

            const finishLoad = () => {
                setReturnedList((prev) => {
                    return prev.map((order) => {
                        return { ...order, isLoading: false };
                    });
                });
            };

            if (
                orderExisted
                    .map((order) => order.orderNumber)
                    .includes(input.toLowerCase())
            ) {
                return finishLoad();
            }

            if (
                returnedList.map((order) => order.orderNumber).includes(input)
            ) {
                handleErrorAudio();
                return finishLoad();
            }

            try {
                const response = await orderApi.findOrder(params.shopId, input);
                if (response.status === 200) {
                    setOrderExisted((prev) => [...prev, response.data.data]);
                }
                finishLoad();
            } catch (error) {
                console.log(error);
                setKindOfModal('error');
                setOpenModal(true);
                setOrderApiMsg(error.response.data.message);
                finishLoad();
            }
        }
    };

    const dataFilter = returnedList.map((order) => {
        return {
            ...order,
            isExisted: orderExisted
                .map((item) => item.orderNumber)
                .includes(order.orderNumber.toLowerCase()),
            isDuplicate:
                returnedList.filter(
                    (item) =>
                        item.orderNumber.toLowerCase() ===
                        order.orderNumber.toLowerCase()
                ).length > 1,
            isReturned:
                orderExisted.find(
                    (item) =>
                        item.orderNumber === order.orderNumber.toLowerCase()
                )?.status === 'returned',
            createAt:
                orderExisted.find(
                    (item) =>
                        item.orderNumber === order.orderNumber.toLowerCase()
                )?.createAt || undefined,
            shippingUnit:
                orderExisted.find(
                    (item) =>
                        item.orderNumber === order.orderNumber.toLowerCase()
                )?.shippingUnit || undefined,
        };
    });

    const handleDelete = (id) => {
        setReturnedList((prev) => {
            return prev.filter((order, index) => index !== id);
        });
    };

    const checkAllOrder =
        dataFilter.every(
            (order) => order.isExisted === true && order.isReturned === false
        ) && returnedList.length > 0;

    const handleSubmit = async () => {
        //1, check all order
        if (!checkAllOrder) return;

        //2, If ok, Call API update Order Returned
        setKindOfModal('loading');
        const dataRequest = returnedList.map((order) => order.orderNumber);

        try {
            const { data } = await orderApi.updateOrderReturned(params.shopId, {
                returnedList: dataRequest,
            });
            console.log(data);
            setKindOfModal('success');
            setOrderApiMsg(
                `Cập nhật thành công ${data.data.length}/${dataRequest.length} đơn hàng`
            );
            //remove localStorage
            localStorage.removeItem(ORDER_EXISTED_RETURNED);
            localStorage.removeItem(RETURNED_LIST);
            setOrderExisted([]);
            setReturnedList([]);
        } catch (error) {
            setOrderApiMsg(error.response.data.message);
            setKindOfModal('error');
        }
    };

    const handleYesNo = () => {
        if (checkAllOrder) setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setKindOfModal('yesno');
    };

    const handleUpload = async () => {
        setLoadingUpload(true);
        setOrderApiMsg('');
        try {
            const form = new FormData();
            form.append('xlsx', file);
            const { data } = await orderApi.importOrderReturned(
                params.shopId,
                form
            );
            console.log(data);
            setOrderApiMsg(
                `Cập nhật thành công ${data.data.orderCounter} đơn hàng`
            );
        } catch (error) {
            console.log(error);
            handleErrorAsync(error, setOrderApiMsg);
        }
        setLoadingUpload(false);
    };

    const handleCloseUpload = () => {
        setFile('');
        setOpenModal(false);
        setKindOfModal('yesno');
        setOrderApiMsg('');
    };

    //Mai update
    const handleDownloadExample = async () => {
        try {
            const { data } = await shopApi.exampleReturned();
            const wookbook = utils.book_new();
            const wooksheet = utils.json_to_sheet(data.data);
            utils.book_append_sheet(wookbook, wooksheet, 'DON_HANG');
            const newFILE = write(wookbook, {
                type: 'buffer',
                bookType: 'xlsx',
            });

            //convert to url a
            const url = window.URL.createObjectURL(new Blob([newFILE]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `example-order-returned.xlsx`);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            setOpenModal(true);
            setKindOfModal('error');
            handleErrorAsync(error, setOrderApiMsg);
        }
    };

    const handleErrorAudio = () => {
        errorAudioRef.current.load();
        errorAudioRef.current.play();
    };

    return (
        <div className="h-full w-full flex justify-center overflow-auto">
            <audio ref={audioRef}>
                <source
                    type="audio/mpeg"
                    src="/audio/barcode_sound.mp3"
                    hidden
                />
            </audio>
            <audio ref={errorAudioRef}>
                <source
                    type="audio/mpeg"
                    src="/audio/error-sound2.mp3"
                    hidden
                />
            </audio>
            <div className=" w-full h-full flex flex-col justify-between items-center gap-10  2xl:w-[1280px]">
                <div className="flex w-full gap-5">
                    <div className="flex-1">
                        <input
                            onKeyDown={handleAdd}
                            className=" p-6 h-4 w-full bg-gray-300  outline-none rounded-lg"
                            placeholder="Nhập mã vận đơn"
                            type="text"
                            value={input}
                            onChange={(e) => setInput(e.target.value.trim())}
                        />
                    </div>

                    <div className="flex">
                        <Button
                            onClick={handleAdd}
                            backgroundColor="black"
                            rounder
                            width={48}
                        >
                            <FontAwesomeIcon icon={faAdd} />
                        </Button>
                    </div>
                    <div className={`h-full aspect-square relative`}>
                        <UploadBtn
                            onDownLoadExample={handleDownloadExample}
                            setFile={setFile}
                            file={file}
                        />
                    </div>
                </div>

                <div className="bg-white flex flex-col shadow-lg h-full w-full rounded-lg overflow-auto">
                    <table className="w-full border-collapse grid">
                        <thead className="sticky top-0 bg-slate-300">
                            <tr className="grid grid-cols-12 p-2 border-[0.5px] text-left">
                                <th className="col-span-1">STT</th>
                                <th className="col-span-3">Mã vận đơn</th>
                                <th className="col-span-3">Nhà vận chuyển</th>
                                <th className="col-span-2">Ngày tạo</th>
                                <th className="col-span-2 text-center">
                                    Trạng thái
                                </th>
                                <th className="col-span-1 text-center">
                                    <FontAwesomeIcon icon={faEllipsis} />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataFilter.map((order, index) => (
                                <tr
                                    key={index}
                                    className="hover:bg-slate-100 grid grid-cols-12 p-2 border-[0.5px] items-center"
                                >
                                    <td className="col-span-1">{index + 1}</td>
                                    <td className="col-span-3">
                                        {order.orderNumber}
                                    </td>
                                    <td className="col-span-3 text-ellipsis whitespace-nowrap">
                                        {order.isLoading ? (
                                            <span className="flex justify-center items-center animate-spin">
                                                <FontAwesomeIcon
                                                    icon={faSpinner}
                                                />
                                            </span>
                                        ) : order.shippingUnit ? (
                                            <span>
                                                {order.shippingUnit.name}
                                            </span>
                                        ) : (
                                            <span className="text-sm w-24 text-center bg-red-100 text-red-500 px-2 rounded-2xl">
                                                Không xác định
                                            </span>
                                        )}
                                    </td>
                                    <td className="col-span-2 whitespace-nowrap text-ellipsis">
                                        {order.isLoading ? (
                                            <span className="flex justify-center items-center animate-spin">
                                                <FontAwesomeIcon
                                                    icon={faSpinner}
                                                />
                                            </span>
                                        ) : order.createAt ? (
                                            <span>
                                                {format(
                                                    new Date(),
                                                    'dd-MM-yyyy'
                                                )}
                                            </span>
                                        ) : (
                                            <span className="text-sm whitespace-nowrap w-24 text-center bg-red-100 text-red-500 px-2 rounded-2xl">
                                                Không xác định
                                            </span>
                                        )}
                                    </td>
                                    <td className="col-span-2 text-center">
                                        {order.isLoading ? (
                                            <span className="flex justify-center items-center animate-spin">
                                                <FontAwesomeIcon
                                                    icon={faSpinner}
                                                />
                                            </span>
                                        ) : (
                                            <div className="flex justify-center cursor-default items-center flex-col gap-1">
                                                {order.isDuplicate && (
                                                    <span className="text-sm w-24 text-center bg-orange-100 text-orange-500 px-2 rounded-2xl">
                                                        Trùng đơn
                                                    </span>
                                                )}
                                                {order.isExisted &&
                                                    !order.isDuplicate &&
                                                    !order.isReturned && (
                                                        <span className="text-green-600">
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faCheckCircle
                                                                }
                                                            />
                                                        </span>
                                                    )}
                                                {order.isExisted &&
                                                    order.isReturned && (
                                                        <span className="text-sm w-24 text-center bg-red-100 text-red-500 px-2 rounded-2xl">
                                                            Đã hoàn
                                                        </span>
                                                    )}
                                                {!order.isDuplicate &&
                                                    !order.isExisted && (
                                                        <span>
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faXmarkCircle
                                                                }
                                                            />
                                                        </span>
                                                    )}
                                            </div>
                                        )}
                                    </td>
                                    <td className="col-span-1 text-center">
                                        <span
                                            onClick={() => handleDelete(index)}
                                            className="p-2 rounded-full text-gray-600 hover:text-gray-950"
                                        >
                                            <FontAwesomeIcon
                                                icon={faTrashCan}
                                            />
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {dataFilter.length === 0 && (
                        <div className="flex-1 gap-4 flex justify-center items-center">
                            <span className="text-slate-300 text-[60px]">
                                <FontAwesomeIcon icon={faBoxOpen} />
                            </span>
                        </div>
                    )}
                </div>

                <div className=" w-full flex gap-5 justify-end items-center 2xl:w-[1280px]">
                    <div className="h-16 w-28 flex">
                        <Button
                            onClick={handleYesNo}
                            disable={!checkAllOrder}
                            rounder
                            fontWeight="600"
                        >
                            CẬP NHẬT
                        </Button>
                    </div>
                </div>

                <Modal isOpen={openModal}>
                    {kindOfModal === 'yesno' && (
                        <YesNo
                            action={handleSubmit}
                            setOpenModal={handleCloseModal}
                            yes="Xác nhận"
                            no="Huỷ"
                            title="Bạn đang thực hiện tạo đơn"
                        />
                    )}
                    {kindOfModal === 'loading' && <CircleLoader />}
                    {kindOfModal === 'success' && (
                        <AlertBox
                            type={kindOfModal}
                            onClose={handleCloseModal}
                            message={orderApiMsg}
                        />
                    )}
                    {kindOfModal === 'error' && (
                        <AlertBox
                            type={kindOfModal}
                            onClose={handleCloseModal}
                            message={orderApiMsg}
                        />
                    )}
                    {kindOfModal === 'upload' && (
                        <UploadOrderForm
                            file={file}
                            title="Tải lên đơn hoàn"
                            onSubmit={handleUpload}
                            message={orderApiMsg}
                            onClose={handleCloseUpload}
                            isLoading={loadingUpload}
                        />
                    )}
                </Modal>
            </div>
        </div>
    );
}

export default OrderReturned;
