import {
    faChevronLeft,
    faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getDate } from 'date-fns';
import React, { useState } from 'react';
import MonthPicker from './MonthPicker';
import YearPicker from './YearPicker';

function Calendar({
    onHoverDate,
    hoverDate,
    onSelectDate,
    dateRange,
    month,
    year,
    onChevron,
    name,
    monthLeft,
    monthRight,
    yearLeft,
    yearRight,
    isToday,
    setMonth,
    setYear,
}) {
    const LAST_DATE_OF_MONTH = new Date(year, month + 1, 0).getDate();
    const LAST_DATE_OF_LAST_MONTH = new Date(year, month, 0).getDate();
    const LAST_DAY_IN_MONTH = new Date(year, month + 1, 0).getDay();
    const FIRT_DAY_IN_MONTH = new Date(year, month, 1).getDay();
    const weekList = ['T2', 'T3', 'T4', 'T5', 'T6', 'T7', 'CN'];
    const monthList = [
        'Tháng 1',
        'Tháng 2',
        'Tháng 3',
        'Tháng 4',
        'Tháng 5',
        'Tháng 6',
        'Tháng 7',
        'Tháng 8',
        'Tháng 9',
        'Tháng 10',
        'Tháng 11',
        'Tháng 12',
    ];

    const [openMonth, setOpenMonth] = useState(false);
    const [openYear, setOpenYear] = useState(false);

    const hoverStyles =
        'hover:font-medium hover:text-white before:hover:bg-violet-500';

    let dateList = [];

    for (
        let i = 0;
        FIRT_DAY_IN_MONTH === 0 ? i < 6 : i < FIRT_DAY_IN_MONTH - 1;
        i++
    ) {
        dateList.unshift({
            date: new Date(
                month === 0 ? year - 1 : year,
                month === 0 ? 11 : month - 1,
                LAST_DATE_OF_LAST_MONTH - i
            ).getTime(),
            isDisable: true,
        });
    }

    for (let i = 1; i <= LAST_DATE_OF_MONTH; i++) {
        dateList.push({
            date: new Date(year, month, i).getTime(),
            isDisable: new Date(year, month, i).getTime() > isToday,
        });
    }

    for (
        let i = 1;
        LAST_DAY_IN_MONTH === 0 ? i < 0 : i <= 7 - LAST_DAY_IN_MONTH;
        i++
    ) {
        dateList.push({
            date: new Date(
                month === 11 ? year + 1 : year,
                month === 11 ? 0 : month + 1,
                i
            ).getTime(),
            isDisable: true,
        });
    }

    const handeleOnChevron = (e) => {
        if (name) return onChevron(e, name);
        onChevron(e);
    };

    return (
        <div className="cursor-default relative select-none w-72 bg-white p-2 shadow-lg rounded-lg">
            <div className="flex font-semibold justify-between items-center p-2">
                <span
                    className={`px-2 py-1 rounded-lg hover:bg-gray-300 ${
                        new Date(yearLeft, monthLeft) >=
                            new Date(year, month - 1) &&
                        'text-gray-300 hover:bg-transparent'
                    }`}
                    onClick={handeleOnChevron}
                    data-value="left"
                >
                    <FontAwesomeIcon
                        className="pointer-events-none"
                        icon={faChevronLeft}
                    />
                </span>
                <div className="flex gap-4">
                    <span
                        className="hover:bg-slate-200 p-2 rounded-lg"
                        onClick={() => setOpenMonth(true)}
                    >
                        {monthList[month]}
                    </span>
                    <span
                        onClick={() => setOpenYear(true)}
                        className="hover:bg-slate-200 p-2 rounded-lg"
                    >
                        {year}
                    </span>
                </div>
                <span
                    className={`px-2 py-1 rounded-lg hover:bg-gray-300 ${
                        new Date(yearRight, monthRight) <=
                            new Date(year, month + 1) &&
                        'text-gray-300 hover:bg-transparent'
                    }`}
                    onClick={handeleOnChevron}
                    data-value="right"
                >
                    <FontAwesomeIcon
                        className="pointer-events-none"
                        icon={faChevronRight}
                    />
                </span>
            </div>
            <div>
                <ul className="grid grid-cols-7 mb-2 justify-center items-center text-center">
                    {weekList.map((el, index) => (
                        <li className="p-2 text-sm" key={index}>
                            {el}
                        </li>
                    ))}
                </ul>
                <ul className="grid grid-cols-7 justify-center items-center text-center ">
                    {dateList.map((el, index) => (
                        <li
                            data-value={el.date}
                            onClick={el.isDisable ? () => {} : onSelectDate}
                            onMouseOver={el.isDisable ? () => {} : onHoverDate}
                            className={`p-2 text-xs relative z-10 before:content before:absolute before:w-6 before:h-6 before:left-1/2 before:top-1/2 before:-translate-y-1/2 before:-translate-x-1/2 before:-z-10 before:rounded-full

                            ${
                                el.date === isToday &&
                                !dateRange.startDate &&
                                !dateRange.endDate &&
                                'before:bg-violet-500 text-white'
                            }
                            ${
                                (el.date === dateRange.startDate ||
                                    el.date === dateRange.endDate) &&
                                'text-white before:bg-violet-500'
                            }
                            ${
                                el.date > dateRange.startDate &&
                                el.date < dateRange.endDate &&
                                'before:bg-violet-200'
                            }
                            ${
                                hoverDate &&
                                dateRange.startDate &&
                                !dateRange.endDate &&
                                (hoverDate > dateRange.startDate
                                    ? el.date > dateRange.startDate &&
                                      el.date <= hoverDate &&
                                      'before:bg-violet-200'
                                    : el.date >= hoverDate &&
                                      el.date < dateRange.startDate &&
                                      'before:bg-violet-200')
                            }
                            ${
                                el.isDisable
                                    ? 'text-gray-300 before:bg-white'
                                    : hoverStyles
                            } `}
                            key={index}
                        >
                            {getDate(el.date)}
                        </li>
                    ))}
                </ul>
            </div>
            <MonthPicker
                setMonth={setMonth}
                month={month}
                name={name}
                openMonth={openMonth}
                setOpenMonth={setOpenMonth}
            />
            <YearPicker
                setYear={setYear}
                year={year}
                name={name}
                openYear={openYear}
                setOpenYear={setOpenYear}
            />
        </div>
    );
}

export default Calendar;
