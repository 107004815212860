import React, { useState } from 'react';
import shopApi from '../../api/shopApi';
import ShopCard from './ShopCard';
import Modal from '../../components/Modal/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faShop } from '@fortawesome/free-solid-svg-icons';
import Button from '../../components/Button/Button';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import NewShop from './NewShop';
import { redirect, useLoaderData } from 'react-router-dom';

function Shop() {
    const dataFetched = useLoaderData();
    const [shops, setShops] = useState(dataFetched.data || []);
    const [isOpen, setIsOpen] = useState(false);

    const handleClickPlus = () => {
        setIsOpen(true);
    };

    return (
        <div className="h-screen w-full p-5">
            <div className="fixed z-10 h-16 w-full top-0 left-0 flex justify-between items-center px-8 bg-white shadow-lg">
                <p className="text-xl font-medium">Danh sách cửa hàng</p>
                <div className="flex">
                    <Tippy content="Thêm shop">
                        <div className="flex">
                            <Button
                                onClick={handleClickPlus}
                                width={40}
                                rounder
                                backgroundColor={'#101828'}
                            >
                                <FontAwesomeIcon icon={faAdd} />
                            </Button>
                        </div>
                    </Tippy>
                </div>
            </div>
            <div className="mt-16 pb-16">
                <div className="flex flex-wrap justify-center items-center gap-5">
                    {shops.map((shop) => (
                        <ShopCard
                            setShops={setShops}
                            key={shop._id}
                            shop={shop}
                        />
                    ))}
                    {shops.length === 0 && (
                        <div className="flex flex-col justify-center items-center gap-2 text-center">
                            <span className="text-slate-200 text-[80px]">
                                <FontAwesomeIcon icon={faShop} />
                            </span>
                            <span>Oh! No! Bạn chưa có shop nào</span>
                            <div className="flex w-40 p-4 justify-center">
                                <Button onClick={handleClickPlus} rounder>
                                    Tạo shop
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <Modal isOpen={isOpen}>
                <NewShop setShops={setShops} setIsOpen={setIsOpen} />
            </Modal>
        </div>
    );
}

export default Shop;

Shop.loader = async () => {
    try {
        const { data } = await shopApi.getShop();
        return data;
    } catch (error) {
        console.log(error);
        if (error.response?.status === 401) {
            localStorage.removeItem('access_token');
            return redirect('/login');
        }

        throw new Response(JSON.stringify(error.response), {
            status: error.response?.status,
        });
    }
};
