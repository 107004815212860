import React, { useState } from 'react';
import shopApi from '../../api/shopApi';
import Button from '../../components/Button/Button';
import handleErrorAsync from '../../utils/handleCatchAsync';

function NewShop({ setIsOpen, setShops }) {
    const [inValid, setInValid] = useState(false);
    const [newShop, setNewShop] = useState('');
    const [errMsg, setErrMsg] = useState();

    const handleClose = () => {
        setIsOpen(false);
        setNewShop('');
        setErrMsg();
        setInValid(false);
    };

    const handleOnchange = (e) => {
        setInValid(false);
        setNewShop(e.target.value);
    };

    const handleAdd = async () => {
        if (newShop.trim() === '' || newShop.length < 4) {
            setInValid(true);
            return;
        }

        try {
            const { data } = await shopApi.createShop({
                shopname: newShop.trim(),
            });
            setShops((prev) => [data.data, ...prev]);
            setNewShop('');
            setErrMsg('');
            setIsOpen(false);
        } catch (error) {
            handleErrorAsync(error, setErrMsg);
            setInValid(true);
        }
    };

    return (
        <div className="w-96 p-4 animate-zoomIn bg-white  rounded-lg grid grid-cols-1 gap-y-4 ">
            <div>
                <span className="pr-2">Thêm shop mới</span>
                <span className="text-xs">* tối thiểu 4 ký tự</span>
            </div>
            {errMsg && <span className="text-red-500 text-sm">{errMsg}</span>}
            <input
                className={`w-full p-3 bg-slate-200 rounded-lg ${
                    inValid && 'animate-shake'
                }`}
                onChange={handleOnchange}
                value={newShop}
                placeholder="Tên shop của bạn"
            />
            <div className="flex gap-5 h-10">
                <Button onClick={handleAdd} rounder>
                    Thêm
                </Button>

                <Button onClick={handleClose} rounder border>
                    Huỷ
                </Button>
            </div>
        </div>
    );
}

export default NewShop;
