import React, { useState } from 'react';
import Button from '../../../components/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import orderApi from '../../../api/orderApi';
import { useParams } from 'react-router-dom';

function AddNote({ order, onClose, setOrderList }) {
    const params = useParams();
    const [input, setInput] = useState('');
    const [apiErrorMsg, setApiErrorMsg] = useState('');
    const [isLoading, setIsLoading] = useState('');
    const [validateInput, setValidateInput] = useState(true);

    const handleClose = () => {
        setInput('');
        onClose();
    };

    const handleSubmit = async () => {
        if (input.trim()) {
            setIsLoading(true);
            try {
                const { data } = await orderApi.updateNote(
                    params.shopId,
                    order._id,
                    { note: input }
                );
                console.log(data);
                setOrderList((prev) => {
                    return prev.map((el) => {
                        if (el._id === order._id) {
                            return {
                                ...el,
                                notes: data.data.data.notes,
                            };
                        }
                        return el;
                    });
                });
                handleClose();
            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        } else {
            setApiErrorMsg('no input');
            setValidateInput(false);
        }
    };

    return (
        <div className="w-96 bg-white shadow-lg animate-zoomIn rounded-lg overflow-auto p-5 flex flex-col gap-8">
            {order && (
                <div className="flex flex-col gap-2">
                    <span className="font-bold text-xl text-slate-700">
                        {`Tạo ghi chú #${order.notes.length + 1}`}
                    </span>
                    <span>{`ID: ${order.orderNumber}`}</span>
                </div>
            )}
            {apiErrorMsg && (
                <span className="border-red-500 border p-2 rounded-lg flex gap-2 items-center justify-center">
                    <FontAwesomeIcon icon={faXmarkCircle} />
                    {apiErrorMsg}
                </span>
            )}
            <input
                value={input}
                onChange={(e) => setInput(e.target.value)}
                className={`w-full bg-gray-200 px-5 py-8 rounded-lg outline-none ${
                    !validateInput && 'animate-shake border border-red-500'
                }`}
                placeholder="Thêm ghi chú"
                type="text"
            />
            <div className="flex gap-5">
                <Button disable={isLoading} onClick={handleSubmit} rounder>
                    {isLoading ? (
                        <span className="animate-spin">
                            <FontAwesomeIcon icon={faSpinner} />
                        </span>
                    ) : (
                        'Xác nhận'
                    )}
                </Button>
                <Button onClick={handleClose} border rounder>
                    Huỷ bỏ
                </Button>
            </div>
        </div>
    );
}

export default AddNote;
